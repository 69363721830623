<app-breadcrumb
  [data]="dataBreadcrumb"
  [goBackText]="'Voltar à visão geral'"
></app-breadcrumb>

<app-header-produtividade
  [tituloHeader]="header.titulo"
  [item1]="header.periodo"
  [item2]="header.resumo"
  [progresso]="header.progresso"
></app-header-produtividade>

<main class="operator-list-container">

  <h3>Avaliação de produtividade</h3>

  <app-generic-table
    [tHead]="tHeadOperator"
    [data]="tBodyOperator"
    [tableConfig]="tableConfig"
    [enableFilters]="true"
    [filters]="tableFilter"
    [enableSearch]="true"
    [searchInputConf]="searchConfig"
    [paginationLabel]="'10 a 20 Operadores'"
    (events)="handleEventTable($event)"
    [currentPage]="1"
    [totalOfEntries]="11"
    [enableVirtualMode]="true"
  ></app-generic-table>
</main>


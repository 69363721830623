export enum EGroupType {
  AUTOMATICA = "AUTOMATICA",
  MANUAL = "MANUAL",
}

export interface ICitation {
  id: number;
  title: string;
}

export interface IGroup {
  id: string;
  backId: number;
  title: string;
  typeGroup: EGroupType;
  total: number;
  porcentagem?: number | null;
  citation: ICitation[];
  groups?: IGroup[];
}

export interface IEditTitle {
  id: string | number;
  newTitle: string;
}

export interface IRemoveAnswerOrChild {
  idGroup: string;
  idChildGroup?: number | string;
  idAnswer?: number | string;
}

export interface ISelectedAvulsesAndGroup{
  avulses: ICitation[];
  groups: IGroup[];
}

// interfaces relacionadas as respostas do servidor (GET)
export interface IGroupResponse {
  id: number;
  citacao: string;
  total: number;
  porcentagem: number | null;
  respostas: ICitationResponse[];
  tipo: EGroupType;
  filhas: IGroupResponse[];
}

export interface ICitationResponse {
  id: number;
  resposta: string;
}

export interface ICitationAndGroupResponse {
  avulsas: ICitationResponse[];
  consolidacoes: IGroupResponse[];
  automatch?: IGroupResponse[];
}

// Interfaces relacionadas a persistência de uma consolidação (PATCH)
export interface IConsolidation {
  backId: number | null;
  respostas: ICitation[];
  consolidacoes: IGroup[];
  title: string;
  tipo: EGroupType;
}

export interface IConsolidationPayload {
  id: number | null;
  respostas: number[] | null;
  consolidacoes: number[] | null;
  citacao: string;
  tipo: EGroupType;
}

export interface IConsolidationResponse {
  id: number;
  valor_percentual: number;
}

export interface IConsolidationBodyResponse {
  body: IConsolidationResponse;
}

<section>
  <div class="selector-card">
    <h5>Análise de respostas abertas</h5>
    <p>Selecione uma pergunta para edição das respostas</p>
    <div class="selector-wrapper">
      <app-filterable-select
        filterPlaceholder="Buscar..."
        label=""
        placeholder="Selecione uma pergunta..."
        [data]="questionData"
        usingBy="AUDITORIA"
        (changeSelectedItem)="changeSelectedItem($event)"
        [selectedItem]="null"
      >
      </app-filterable-select>
    </div>
  </div>
  <div *ngIf="!selectedQuestionId" class="empty-anwser">
    <svg
      width="580"
      height="302"
      viewBox="0 0 580 302"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3108_9416)">
        <path
          class="polygon-one"
          d="M-115 -2V72.47C-90.4526 74.4181 -65.6732 77.0263 -40.862 80.2762C14.9326 87.5953 71.1593 98.2189 126.262 111.856C135.832 114.223 145.375 116.685 154.885 119.239C156.85 108.501 158.951 98.0778 161.18 87.973C169.549 50.0712 178.891 20.681 188.006 -1.99545H-115V-2Z"
          fill-opacity="0.4"
        />
        <path
          class="polygon-two"
          d="M-40.862 80.2759C-65.6732 77.026 -90.4526 74.4178 -115 72.4697V347H133.681C133.754 344.137 133.826 341.269 133.913 338.397C136.314 255.602 143.36 182.065 154.885 119.238C145.375 116.689 135.832 114.222 126.262 111.855C71.1638 98.2186 14.9372 87.595 -40.862 80.2759Z"
          fill-opacity="0.1"
        />
        <path
          class="polygon-three"
          d="M581.693 347V307.965C562.954 295.916 543.996 284.3 524.852 273.158C409.11 205.784 284.781 154.068 154.881 119.238C143.36 182.065 136.314 255.602 133.908 338.397C133.826 341.274 133.749 344.137 133.676 347H581.688H581.693Z"
          fill-opacity="0.4"
        />
      </g>
      <defs>
        <clipPath id="clip0_3108_9416">
          <rect
            width="696.693"
            height="349"
            fill="#fff"
            transform="translate(-115 -2)"
          />
        </clipPath>
      </defs>
    </svg>
    <p [style.margin-left.ch]="-(messageContent.length / 2)">
      {{ messageContent }}
    </p>
  </div>

  <div *ngIf="selectedQuestionId" class="consolidation-container">
    <app-step-one
      *ngIf="requestCompleted"
      [groupList]="groupArray"
      [citationList]="avulsesArray"
    ></app-step-one>
    <app-step-two [groups]="groupArray" (matchAnswers)="matchCitationAndGroup($event)"></app-step-two>
  </div>
</section>

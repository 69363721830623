<div class="custom-audio-container">
  <div class="buttons-wrapper">

    <div class="speed-buttons" (mouseleave)="toggleSpeedOptions()">
      <button
        [ngClass]="showSpeedOptions ? 'speed-btn-none' : ''"
        (click)="handleAudioSpeed(audioSpeed)"
        (mouseenter)="toggleSpeedOptions()"
      >
       <span class="selected-btn">{{ audioSpeed }}x</span>
      </button>
      <div class="options" *ngIf="showSpeedOptions">
        <button *ngFor="let speed of speedOptions" (click)="handleAudioSpeed(speed)">
          <span [ngClass]="speed === audioSpeed ? 'selected-btn' : ''">{{ speed }}x</span>
        </button>
      </div>
    </div>

    <button class="advance-or-rewind-btn" (click)="handleRewindAudio()">
      <i class="fa-light fa-rotate-left control-audio"></i>
    </button>

    <i *ngIf="!audioIsPlaying"  class="fa-solid fa-circle-play play-btn" (click)="handleToggleAudio()"></i>
    <i *ngIf="audioIsPlaying" class="fa-solid fa-circle-pause play-btn" (click)="handleToggleAudio()"></i>

    <button [disabled]="!audioHeard" class="advance-or-rewind-btn" (click)="handleAdvanceAudio()">
      <i
        [ngClass]="{
          'fa-light fa-rotate-right control-audio': true,
          'advance-or-rewind-disabled': !audioHeard
        }"
      ></i>
    </button>

    <!-- Botão para controlar o volume -->
    <div class="volume-control-container" (mouseleave)="showVolumeControl()">
      <button class="volume-button" (click)="toggleMute()" (mouseenter)="showVolumeControl()">
        <i *ngIf="!isMuted" class="fa-sharp fa-regular fa-volume"></i>
        <i *ngIf="isMuted" class="fa-regular fa-volume-xmark"></i>
      </button>
      <div class="volume-control" *ngIf="showVolume" [style.--volume-value]="volumeValue">
        <input
          class="volume-input"
          type="range"
          [min]="0"
          [max]="1"
          [step]="0.01"
          [(ngModel)]="volumeValue"
          (input)="updateVolume()"
        >
      </div>
    </div>
  </div>


  <audio controls #audioPlayer (loadeddata)="onLoad($event)" style="display: none;">
    <source [src]="audioToPlay" type="audio/mp3">
  </audio>

  <div class="soundbar-wrapper">
    <label class="time-label">{{ currentTimeDisplay }}</label>
    <input
      type="range" class="custom-progress-bar"
      [max]="durationTime"
      [(ngModel)]="currentTime"
      (input)="progressBarControl($event)"
      [style.--percent-value]="(currentTime / durationTime)"
      [disabled]="!audioHeard"
    >
    <label class="time-label">{{ audioDurationDisplay }}</label>

    <a [href]="audioToPlay" download class="down-icon">
      <i class="fa-sharp fa-solid fa-circle-down"
      (mouseenter)="handleShowDownloadTooltip()"
      (mouseleave)="handleShowDownloadTooltip()"
      >
    </i>
    </a>
    <span *ngIf="showDownloadTooltip" class="down-tooltip">Baixar áudio</span>

  </div>

</div>


import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RequestService } from "app/servico/request.service";
import { CrudService } from "app/servico/requestService/crudService";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { Observable } from "rxjs";
import { IImageTheme } from "styles/interface";

interface ThemeManagerRequest {
  id: string;
}

interface ThemeManagerResponse {
  tema: string;
  imagem: IImageTheme;
}

@Injectable({
  providedIn: "root",
})
export class ThemeManagerService extends CrudService<ThemeManagerRequest> {
  constructor(
    protected requestService: RequestService,
    private http: HttpClient
  ) {
    super(requestService, `${apiLocation}`);
  }

  private baseUrl: string;

  /**
   * Função que é responsável por recuperar o tema de acordo com o contratante logado
   * @param hirer contratante que está logado no sistema
   * @returns {tema: 'nome_do_tema'}
   */
  getThemeByHirer(hirer: string): Observable<ThemeManagerResponse> {
    this.baseUrl = `${this.resourcePath}/contratantes/${hirer}/customizacao`;
    const response = this.requestService.get(this.baseUrl);
    return response;
  }

}

import { Injectable } from "@angular/core";
import { EGroupType, ICitation, IGroup } from "../../interfaces/audit-open-answer";

@Injectable({
  providedIn: "root",
})
export class ConsolidationService {
  constructor() {}

  public processManualMatch(group, selectedAvulsesAndGroups) {
    const { avulses, citationFromGroups, defaultGroups } =
      this.getSelectAvulseAndGroups(group, selectedAvulsesAndGroups);

    const newCitationArray = this.buildNewCitationArray(
      avulses,
      citationFromGroups,
    );

    const newGroup: IGroup = this.buildNewGroup(
      group,
      newCitationArray,
      defaultGroups,
      avulses
    );

    return newGroup;
  }

  /**
   * Obtem os grupos e avulsas que foram selecionadas
   */
  private getSelectAvulseAndGroups(group: IGroup, selectedAvulsesAndGroups) {
    const { avulses, groups } = selectedAvulsesAndGroups || {
      avulses: [],
      groups: [],
    };
    if (group.id) {
      groups.push(group);
    }
    //Recupera as alternativas que compõe grupos que foram criados manualmente
    const citationFromGroups = groups
      .filter(({ typeGroup }) => typeGroup === EGroupType.MANUAL)
      .reduce((acc, curr) => acc.concat(curr.citation), []);

    const defaultGroups: IGroup[] = this.createChildrenGroup(groups);

    return {
      avulses,
      citationFromGroups,
      defaultGroups,
    };
  }

  /**
   * Recupera os grupos criados por automatch para compor o array de filhas
   * retorna um IGroup[]
   */
  private createChildrenGroup(groups): IGroup[] {
    const uniqueDefaultGroups = groups.reduce((acc, group) => {
      if (group.typeGroup === EGroupType.AUTOMATICA) {
        acc.add(group);
      }

      group.groups?.forEach(subGroup => {
        if (subGroup.typeGroup === EGroupType.AUTOMATICA) {
          acc.add(subGroup);
        }
      });

      return acc;
    }, new Set<typeof groups[]>());

    return Array.from(uniqueDefaultGroups);
  }

  /**
   * Cria um novo array de citações sem dados repetidos
   */
  private buildNewCitationArray(
    avulses: ICitation[],
    citationFromGroups: ICitation[],
  ) {
    const newCitationSet = new Set([
      ...avulses,
      ...citationFromGroups,
    ]);
    return Array.from(newCitationSet);
  }

  /**
   * Cria uma nova consolidação manual
   */
  private buildNewGroup(group: IGroup, newCitation, defaultGroups: IGroup[], avulses: ICitation[]) {
    return {
      ...group,
      // Esse reduce combina os ids para criar id unico para o grupo
      id: newCitation.reduce(
        (acc, cur) => (!!acc ? `${acc}_${cur.id}` : cur.id),
        ""
      ),
      citation: newCitation,
      avulses: [...avulses],
      groups: [...defaultGroups],
    };
  }
}

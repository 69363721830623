import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalService } from "app/componentes/modal/modal.service";
import { NotificatorService } from "app/notificador/notificator.service";
import { ContratanteService } from "../../services/contratante.service";
import { IOptionMeatball, ListagemContratantesMenu } from "./meatball/meatball.component";
import { Router } from "@angular/router";

enum License {
  Tensai_Pro = "Tensai Pro",
}

@Component({
  selector: "app-tabela-contratante",
  templateUrl: "./tabela-contratante.component.html",
  styleUrls: ["./tabela-contratante.component.scss"],
})
export class TabelaContratanteComponent implements OnInit {
  @Input() hirerList;
  @Output() updateHeader = new EventEmitter();


  isLoading: boolean = false;
  contractorSchemaName: string;

  constructor(
    private notificatorService: NotificatorService,
    private modalService: ModalService,
    private contractorService: ContratanteService,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  /**
   * Formatar o valor de tipo da licença
   * @param license tipo da liscença que vem do back-end para ser formatado
   * @returns retorna um enum cuja a key é o retorno do back e o value é o resultado a ser apresentado já formatado
   */
  formatLiceseType(license) {
    return License[license];
  }

  /**
   * Função que dispara o modal de verificação para ativação de um contratante, disparado mediante a mudança no status
   * do contratante;
   * @param ativo valor do status que será atribuído
   * @param index índice do array que será feita mudança de status
   */
  onActiveStatusModal(ativo, index) {
    this.modalService.showModal({
      title: "Ativação de Contratante",
      messageModal:
        "Um e-mail será enviado ao administrador para definição de senha. Deseja continuar?",
      icon: "fa-regular fa-plug-circle-plus",
      close: true,
      btnTitlePositive: "Continuar",
      positiveCallback: () => this.setHirerStatus(ativo, index),
    });
  }

  /**
   * Função que dispara o modal de verificação para inativação de um contratante, disparado mediante a mudança no status
   * do contratante;
   * @param ativo valor do status que será atribuído
   * @param index índice do array que será feita mudança de status
   */
  onInactiveStatus(ativo, index) {
    this.modalService.showModal({
      title: "Inativar Contratante",
      messageModal:
        `Deseja mesmo inativar a contratante <b>${this.hirerList[index].nome}</b>?`,
      btnTitlePositive: "Inativar",
      icon: "fa-regular fa-plug-circle-xmark",
      close: true,
      positiveCallback: () => this.setHirerStatus(ativo, index),
    });
  }

  /**
   * Intercepta qualquer mudança de status
   * @param ativo novo status
   * @param index indice do array onde o novo status vai ser alterado
   */
  handleStatusChange(ativo, index, schemaName) {
    this.contractorSchemaName = schemaName;
    if (ativo) {
      if (this.hirerList[index].eh_primeira_ativacao) {
        this.onActiveStatusModal(ativo, index);
      } else {
        this.setHirerStatus(ativo, index);
      }
    } else {
      this.onInactiveStatus(ativo, index);
    }
  }

  /**
   * Esse método tem a responsabilidade mudar o status de uma pesquisa,
   * e enviar requisição para o back-end fazer o msmo
   * @param ativo novo status
   * @param index lugar onde dever ser alterado o status
   */
  setHirerStatus(ativo, index) {

    this.isLoading = true;
    this.contractorService.changeContractorStatus(this.contractorSchemaName, ativo).subscribe({
      next: () => {},
      complete: () => {
        this.isLoading = false;

        const title = `Contratante ${ativo ? "Ativado" : "Inativado"}`;
        const message = `Contratante ${
          ativo ? "ativado" : "inativado"
        } com sucesso`;

        this.hirerList[index].ativo = ativo;
        this.notificatorService.showInfo(title, message);
        this.updateHeader.emit({
          status: ativo
        })
      },
      error: (err) => {
        this.isLoading = false;
        console.log(err)
      }
    });
  }

  getClickOptionMeatball($event: IOptionMeatball, ativo: boolean, index) {
    switch ($event.type) {
      case ListagemContratantesMenu.EDITAR:
        this.router.navigate([`contratante-beta/atualizar/${$event.id}`]);
        break;
      case ListagemContratantesMenu.ACESSARINSTANCIA:
        window.open(`auth/contratante?schema=${$event.id}`);
        break;
      case ListagemContratantesMenu.ATIVAR:
        this.handleStatusChange(!ativo, index, $event.id)
      default:
        break;
    }
  }
}

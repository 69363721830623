export type ProdutividadeSituacaoPesquisas = "em_auditoria" | "nao_auditada" | "pesquisas" | "auditadas";

export enum ProdutividadeSituacaoPesquisasFriendlyNames {
    em_auditoria = "Em Auditoria",
    nao_auditada = "Não Auditada",
    pesquisas = "Pesquisas",
    auditadas = "Auditadas",
}

export interface IInfoCardPayload {
  pesquisas: number,
  auditadas: number,
  em_auditoria: number,
  nao_auditada: number
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  IGroup,
  IRemoveAnswerOrChild,
} from "../../interfaces/audit-open-answer";
import { MatchOperationsService } from "../../services/consolidacao/match-operations.service";

@Component({
  selector: "app-selectable-dropdown",
  templateUrl: "./selectable-dropdown.component.html",
  styleUrls: ["./selectable-dropdown.component.scss"],
})
export class SelectableDropdownComponent implements OnInit {
  @Input() group: IGroup;
  @Input() selected = false;
  @Input() isNewGroup = "";
  @Output() selectGroup: EventEmitter<IGroup> = new EventEmitter<IGroup>();
  @Output()
  handleRemoveCitationOrConsolidation: EventEmitter<IRemoveAnswerOrChild> =
    new EventEmitter<IRemoveAnswerOrChild>();

  showDropdown = false;
  editMode = false;
  newTitle = "";

  constructor(private matchOperationsService: MatchOperationsService) {}

  ngOnInit(): void {}

  toogleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  // retorna a quantidade de respostas de uma consolidação (incluindo as respostas das filhas)
  get citationLength() {
    return this.group.total;
  }

  // Retorna a quantidade respostas da consolidação filha
  groupChildLength(child: IGroup) {
    return `(${child.total})`;
  }

  selectGroups(group: IGroup) {
    this.selectGroup.emit(group);
  }

  toggleEditMode() {
    this.newTitle = this.group.title;
    this.editMode = !this.editMode;
  }

  /**
   * Disparado no click de confirmar, emite o valor para o componente de passo 1.
   * Após isso o estado de edição é mudado para false e o titulo recebe novo valor
   */
  handleChangeGroup() {
    this.group.title = this.newTitle;
    this.matchOperationsService.updateGroupTitle(this.group.id, this.group.title);
    this.newTitle = this.group.title;
    this.toggleEditMode();
  }

  /**
   * Envia o id da citação e o id do grupo ao qual ela pertence
   * @param idGroup id do grupo alterado
   * @param idCitation id da citação que irá retornar para lista de avulsas
   */
  handleDeleteCitationFromGroup(idGroup, idCitation) {
    this.handleRemoveCitationOrConsolidation.emit({
      idAnswer: idCitation,
      idGroup: idGroup,
    });
  }

  /**
   * Envia o id do grupo que sera removido
   * @param idGroup id do grupo alterado
   * @param childIdGroup id do grupo filho
   *
   */
  handleRemoveChildGroup(idGroup, childIdGroup) {
    this.handleRemoveCitationOrConsolidation.emit({
      idChildGroup: childIdGroup,
      idGroup: idGroup,
    });
  }
}

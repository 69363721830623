<div class="header-informacoes-container animate-up delay-2">
  <div class="informacoes-gerais animate-up delay-3">
    <div class="image">
      <img
        src="../../../../../assets/images/informacao-image.png"
        alt="Teste"
      />
    </div>
    <div class="label-descricao">
      <h4>Informações gerais</h4>
      <p>Confira dados relacionados a pesquisa cadastrada</p>
    </div>
  </div>

  <div class="resumo-de-dados">
    <div class="dado-card animate-up delay-3">
      <i class="fa-sharp fa-regular fa-circle-play" alt="Status"></i>
      <div class="dado-descricao">
        <span>Status</span>
        <h5>Execução</h5>
      </div>
    </div>
    <div class="dado-card animate-up delay-3">
      <i class="fa-regular fa-square-user" alt="Coletas"></i>
      <div class="dado-descricao">
        <span>Coletas</span>
        <h5>{{ totalDeColetas }}/{{ totalDeAmostras }}</h5>
      </div>
    </div>
    <div class="dado-card animate-up delay-3">
      <i class="fa-regular fa-ban" alt="Rejeitadas"></i>
      <div class="dado-descricao">
        <span>Rejeitadas</span>
        <h5>{{ totalDeEntrevistasRejeitas }}%</h5>
      </div>
    </div>

    <button class="ver-resultados animate-up delay-3">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_4731_16123">
        <path d="M0 2V16C0 17.103 0.897 18 2 18H16C17.103 18 18 17.103 18 16V2C18 0.897 17.103 0 16 0H2C0.897 0 0 0.897 0 2ZM16.001 16H2V2H16L16.001 16Z"/>
        <path d="M8 4H10V14H8V4ZM12 7H14V14H12V7ZM4 9H6V14H4V9Z"/>
        </mask>
        <path d="M0 2V16C0 17.103 0.897 18 2 18H16C17.103 18 18 17.103 18 16V2C18 0.897 17.103 0 16 0H2C0.897 0 0 0.897 0 2ZM16.001 16H2V2H16L16.001 16Z"/>
        <path d="M8 4H10V14H8V4ZM12 7H14V14H12V7ZM4 9H6V14H4V9Z"/>
        <path d="M16.001 16V17H17.0011L17.001 15.9999L16.001 16ZM2 16H1V17H2V16ZM2 2V1H1V2H2ZM16 2L17 1.99993L16.9999 1H16V2ZM8 4V3H7V4H8ZM10 4H11V3H10V4ZM10 14V15H11V14H10ZM8 14H7V15H8V14ZM12 7V6H11V7H12ZM14 7H15V6H14V7ZM14 14V15H15V14H14ZM12 14H11V15H12V14ZM4 9V8H3V9H4ZM6 9H7V8H6V9ZM6 14V15H7V14H6ZM4 14H3V15H4V14ZM-1 2V16H1V2H-1ZM-1 16C-1 17.6553 0.344715 19 2 19V17C1.44928 17 1 16.5507 1 16H-1ZM2 19H16V17H2V19ZM16 19C17.6553 19 19 17.6553 19 16H17C17 16.5507 16.5507 17 16 17V19ZM19 16V2H17V16H19ZM19 2C19 0.344715 17.6553 -1 16 -1V1C16.5507 1 17 1.44928 17 2H19ZM16 -1H2V1H16V-1ZM2 -1C0.344715 -1 -1 0.344715 -1 2H1C1 1.44928 1.44928 1 2 1V-1ZM16.001 15H2V17H16.001V15ZM3 16V2H1V16H3ZM2 3H16V1H2V3ZM15 2.00007L15.001 16.0001L17.001 15.9999L17 1.99993L15 2.00007ZM8 5H10V3H8V5ZM9 4V14H11V4H9ZM10 13H8V15H10V13ZM9 14V4H7V14H9ZM12 8H14V6H12V8ZM13 7V14H15V7H13ZM14 13H12V15H14V13ZM13 14V7H11V14H13ZM4 10H6V8H4V10ZM5 9V14H7V9H5ZM6 13H4V15H6V13ZM5 14V9H3V14H5Z" mask="url(#path-1-inside-1_4731_16123)"/>
      </svg>

      <span></span>
      RESULTADOS
    </button>
  </div>
</div>

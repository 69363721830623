import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EMAIL_REGEX } from "../constants";
import validadorCpf from "app/util/validador/validadorCpf";
import { NewFotoUploaderComponent } from "app/util/componente/new-foto-uploader/new-foto-uploader.component";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import { IFuncionarioEditPayload, IFuncionarioPayload, IGroups, IPermissoes } from "../interfaces";
import { FuncionarioService } from "../services/funcionario.service";
import { ModalService } from "app/componentes/modal/modal.service";
import { Location } from "@angular/common";
import { NotificatorService } from "app/notificador/notificator.service";
import { ActivatedRoute } from "@angular/router";
import { IVerifyLoginCredentials } from "app/infraestrutura/security/service/loginCredentials";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { registerPayload, updatePermissionsListWhenAGroupIsAdd, updatePayload, updatePermissionsListWhenAGroupIsRemoved, updateAllGroupsListOnEdit, updateAllPermissionsListOnEdit } from "../utils";
import { FuncionarioNavigateService } from "../services/funcionario-navigate.service";
import { TipoUsuarioSistema } from "app/util/usuario/tipoUsuario";

enum ActiveModal {
  PASSWORD = 'PASSWORD',
  EMAIL = 'EMAIL'
}

@Component({
  selector: "app-cadastro-funcionario-beta",
  templateUrl: "./cadastro.component.html",
  styleUrls: ["./cadastro.component.scss"],
})
export class CadastroFuncionarioBetaComponent implements OnInit {
  @ViewChild("statusDropdown", { static: true }) statusDropdown;
  @ViewChild(NewFotoUploaderComponent, { static: true })
  newFotoUploaderComponent: NewFotoUploaderComponent;

  emailRegex = EMAIL_REGEX;

  isEdicao: boolean = false;
  form: FormGroup;
  previousValue;
  isLoading: boolean = false;
  statusIsOpen = false;
  hasChange: boolean = false;
  cancelCheckboxValue: boolean = false;
  editCheckboxValue: boolean = false;
  employeeId: number;
  matricula: number;
  loggedUserIsAdmin: boolean = false;

  allPermissions: IPermissoes[] = [];
  allGroups: IGroups[] = [];

  activeModal: ActiveModal;

  personAndUserIds = {
    userId: null,
    personId: null
  }

  photoSize = {
    width: '100px',
    height: '100px',
    iconHeight: '30px',
    iconWidth: '30px',
    iconFontSize: '12px'
  }

  credentials: IVerifyLoginCredentials = {
    login: this.securityService.getAuthenticatedUser().login,
    password: ''
  }

  newEmail: string;
  dataBreadcrumb: IItemBreadcrumb[];

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private employeeService: FuncionarioService,
    private modalService: ModalService,
    private location: Location,
    private notificator: NotificatorService,
    private securityService: SecurityService,
    private funcionarioNavigateService: FuncionarioNavigateService
  ) {}

  ngOnInit(): void {
    if (this.newFotoUploaderComponent) {
      this.newFotoUploaderComponent.ngOnInit();
    }

    this.form = this.buildForm();
    this.verifyLoggedUserIsAdmin()
    this.getPermissions();
    this.getGroups();
    if(this.loggedUserIsAdmin) {
      this.getGroupPermissions = this.getGroupPermissions.bind(this);
      this.removeGroupPermissions = this.removeGroupPermissions.bind(this);
    }

    this.isEdicao = this.activatedRoute.snapshot.queryParams.hasOwnProperty('id') ? true : false;

    if(this.isEdicao) {
      this.employeeId = this.activatedRoute.snapshot.queryParams?.id
      this.buildFormEditMode();
    }

    this.dataBreadcrumb = [
      {
        itemName: "início",
        itemLink: "/",
        active: false,
      },
      {
        itemName: "Funcionário",
        itemLink: "/funcionario-beta",
        active: false,
      },
      {
        itemName: `${this.isEdicao ? "Editar funcionário" : "Adicionar funcionário"}`,
        itemLink: `/funcionario-beta/${
          this.isEdicao ? "atualizar" : "cadastro"
        }`,
        active: true,
      },
    ];

    this.modalInputControl();
    this.onValueChange();
  }

  /**
   * Controla o valor do input da modal e atribui as devidas validações
   */
  modalInputControl() {
    this.modalService.getInput().subscribe({
      next: (inputValue) => {
        if (this.activeModal === ActiveModal.PASSWORD) {
          this.credentials.password = inputValue

          if(inputValue !== null && !inputValue.length) {
            this.modalService.handleInputErrors(['Senha obrigatória']);
          } else {
            this.modalService.cleanInputErrors();
          }
        } else if (this.activeModal === ActiveModal.EMAIL) {
          this.newEmail = inputValue;

          if(inputValue !== null && !inputValue.length) {
            this.modalService.handleInputErrors(['Campo obrigatório']);
          } else {
            this.modalService.cleanInputErrors();
          }
        }
      }
    })
  }

  /**
   * Realiza a construção do formulário com os dados e validações inciais
   */
  buildForm(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      photo: [""],
      name: ["", [Validators.required, Validators.maxLength(70)]],
      email: ["", [Validators.required, Validators.pattern(this.emailRegex)]],
      cpf: ["", [Validators.required, validadorCpf]],
      role: ["", [Validators.required, Validators.maxLength(70)]],
      status: [true, [Validators.required]],
      permissions: [[], [Validators.required]],
      groups: [[]]
    });
  }

  /**
   * Realiza a construção do formulário com os dados retornados do backend
   */
  buildFormEditMode() {
    this.employeeService.getEmployeeData(this.employeeId).subscribe({
      next: (data) => {
        this.form.setValue({
          id: data?.id,
          photo: data?.foto,
          name: data?.pessoa.nome,
          email: data?.usuario.login,
          cpf: data?.pessoa.cpf,
          role: data?.cargo,
          status: data?.usuario.ativo,
          permissions: this.loggedUserIsAdmin ? this.updatePermissions(data?.usuario.permissoes) : data?.usuario.permissoes,
          groups: this.loggedUserIsAdmin ? this.updateGroupPermissionsOnEdit(data?.usuario.grupos) : data?.usuario.grupos
        });
        this.matricula = data?.matricula
        this.personAndUserIds = {
          userId: data?.usuario.id,
          personId: data?.pessoa.id
        }
        data?.foto !== null && this.newFotoUploaderComponent.loadImageByUrl(data?.foto);
        this.previousValue = this.form.value;
      },
      complete: () => {
        this.onValueChange()
        this.hasChange = false;
      }
    })
  }

  /**
   * Verifica se o usuario logado é admin para listar as permissões
   */
  verifyLoggedUserIsAdmin(): void {
    this.loggedUserIsAdmin = this.securityService.getAuthenticatedUser().tipoUsuario === TipoUsuarioSistema.ROLE_ADMINISTRADOR;
  }

  /**
   * Adiciona os atributos de controle para cada permissão no modo edição
   * Filtra todas as permissões com "ROLE" no nome, pois essas não podem ser
   * editadas e não devem ser exibidas
   * @returns
   */
  updatePermissions(permissions: IPermissoes[]): IPermissoes[] {
    const filteredPermissions = permissions
    .filter(permission => !permission.nomeReal.includes("ROLE"))
    .map(permission => ({
      ...permission,
      selecionado: true,
      disabled: false,
      groupsIds: [],
    }));
    this.allPermissions = updateAllPermissionsListOnEdit(this.allPermissions, filteredPermissions);
    return filteredPermissions
  }

  /**
   * Recupera as permissões do grupo no modo edição
   * @returns
   */
  updateGroupPermissionsOnEdit(groups: IGroups[]): IGroups[] {
    groups.forEach(g => {
      g.selecionado = true
      this.getGroupPermissions(g.id, true)
    })

    updateAllGroupsListOnEdit(this.allGroups, groups)
    return groups
  }


  /**
   * Adiciona imagem settada ao formGroup (ou nulo se não houver upload)
   */
  updateImage():void {
    if (this.newFotoUploaderComponent.defaultImage === this.newFotoUploaderComponent.url) {
      this.form.get('photo')?.setValue(null);
    } else {
      this.form.get('photo')?.setValue(this.newFotoUploaderComponent.url);
    }
  }

  // Controla o valor do campo status
  handleStatusClick(type: boolean): void {
    this.form.get("status")?.setValue(type);
  }

  // realiza o toggle do select de status
  toggleStatus(): void {
    this.statusIsOpen = !this.statusIsOpen;
  }

  /**
   * Escuta evento de click e fecha select quando usuário clica fora da área dele.
   * @param event Evento de click
   */
  @HostListener("document:click", ["$event"])
  documentClick(event: Event) {
    if (!this.statusDropdown.nativeElement.contains(event.target)) {
      if (this.statusIsOpen) {
        this.toggleStatus();
      }
    }
  }

  /**
   * Recupera todas as permissões do funcionários
   */
  getPermissions() {
    this.employeeService.getPermissions().subscribe({
      next: (resp) => {
        this.allPermissions = resp.map((permission) => ({
          ...permission,
          selecionado: false,
          disabled: false,
          groupsIds: [],
        }));
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  /**
   * Recupera os grupos do funcionário
   */
  getGroups() {
    this.employeeService.getGroups().subscribe({
      next: (resp) => {
        const filteredData = resp.map(({ nome, id }) => ({
          nome,
          id,
          selecionado: false,
        }));
        this.allGroups = filteredData;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  /**
   * Recupera as permissões do grupo informado e as atribui ao usuário
   * Se a permissão já existir de forma avulsa o valor de disabled será = true
   * Se a permissão já existir decorrente de outro grupo um novo id será adicionado
   * ao array groupIds para controle.
   */
  getGroupPermissions(groupID: number, editModeFirstRender: boolean = false): void {
    this.employeeService.getGroupPermissions(groupID).subscribe({
      next: (data) => {
        const permissionsArray = this.form.get('permissions').value;

        if(editModeFirstRender) {
          permissionsArray.map(permission => {
            if(data.permissoes.includes(permission.nomeReal)) {
              this.updateSelectedPermission(permission, groupID);
            }
          })

        } else {
          this.allPermissions.map(permission => {
            if(data.permissoes.includes(permission.nomeReal)) {
              if (permission.selecionado) {
                this.updateSelectedPermission(permission, groupID);
              } else {
                this.addNewPermission(permission, groupID);
              }
            }
          });
        }
      },
      complete: () => {
        this.allPermissions = updatePermissionsListWhenAGroupIsAdd(this.allPermissions, this.form.get('permissions').value);
      }
    });
  }

  /**
   * Remove as permissões relacionadas a grupos removidos
   * Se a permissão estiver vinculado com outro grupo, não será podera ser removida
   */
  removeGroupPermissions(groupID: number): void {
    const permissionsArray = this.form.get('permissions').value;
    const updatedPermissionsArray = permissionsArray.filter((permission: IPermissoes) => {
      const groupIndex = permission.groupsIds.indexOf(groupID);

      if (groupIndex !== -1) {
        permission.groupsIds.splice(groupIndex, 1);
      }

      return permission.groupsIds.length > 0 || groupIndex === -1;
    });

    this.form.patchValue({
      permissions: updatedPermissionsArray
    });

    this.allPermissions = updatePermissionsListWhenAGroupIsRemoved(this.allPermissions, permissionsArray);
  }

  /**
   * Atualiza uma permissão já existente para disabled e adiciona o grupo correspondente
   */
  updateSelectedPermission(permission: IPermissoes, groupID: number): void {
    const permissionsArray = this.form.get('permissions').value;
    const foundPermissionIndex = permissionsArray.findIndex(p =>  p.nomeReal === permission.nomeReal);
    if (foundPermissionIndex !== -1) {
      permissionsArray[foundPermissionIndex].disabled = true; // se ela ja pertencer a um grupo esse valor já será true
      permissionsArray[foundPermissionIndex].groupsIds.push(groupID);
    }
  }

  /**
   * Adiciona novas permissões relacionadas ao grupo selecionado
   */
  addNewPermission(permission: IPermissoes, groupID: number): void {
    let newPermission: IPermissoes = {
      nomeReal: permission.nomeReal,
      nomeAmigavel: permission.nomeAmigavel,
      selecionado: true,
      disabled: true,
      groupsIds: [groupID],
    }

    this.form.patchValue({ permissions: [...this.form.get('permissions').value, newPermission]});
  }

  /**
   * Intercepta as mudanças no formulario para saber se o usuário alterou algo durante o cadastro/edição
   * setTimeout utilizado para aguardar o retorno do get com os dados do funcionário
   */
  onValueChange(){
    const initialValue = this.form.value;
    this.form.valueChanges.subscribe(() => {
      this.hasChange = Object.keys(initialValue).some(key => this.form.value[key] != initialValue[key])
    });
  }

  /**
   * Encerra o cadastro
   */
  handleClose(): void {
    this.modalService.getCheckbox().subscribe({next: (data) => this.cancelCheckboxValue = data})
    if(this.hasChange && !this.cancelCheckboxValue) {
      this.modalService.showModal({
        icon: 'fa-regular fa-door-open',
        title: 'Sair sem salvar',
        messageModal: 'Deseja mesmo descartar as alteraçoes realizadas no perfil deste funcionário?',
        btnTitlePositive: 'Sair sem salvar',
        btnTitleNegative: 'Voltar',
        checkbox: true,
        positiveCallback: () => this.location.back()
      })
    } else {
      this.location.back()
    }
  }

  /**
   * Toca todos os campos do formulario para indicar os erros para o usuário
   * @param form: Atributo que representa o formulario
   */
    markFieldsAsTouched(form: FormGroup) {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);

        if (control instanceof FormGroup) {
          this.markFieldsAsTouched(control);
        } else {
          control.markAsTouched();
        }
      });
    }


  /**
   * Retorna o texto da modal baseado na situação do funcinário (ativo/inativo)
   * @returns mensagem da modal
   */
  getRegisterModalText(): string {
    if(this.form.get('status')?.value) {
      return `Deseja ativar o cadastro de ${this.form.get('name')?.value}?<br> Um e-mail de definição de senha será enviado para finalização da ativação.`
    } else {
      return 'Você está adicionando um funcionário com a situação <b>Inativo</b>.<br> Deseja mesmo continuar?'
    }
  }

  employeeRegister() {
    const payload: IFuncionarioPayload = registerPayload(this.form.value);
    this.employeeService.registerEmployee(payload).subscribe({
      next: (data) => {
        this.funcionarioNavigateService.setEmployeeId(data.id)
      },

      complete: () => {
        this.notificator.showInfo(
          'Funcionário adicionado!',
          this.form.get('status')?.value ? 'E-mail de definição de senha enviado!' : ''
        );
        this.location.back();
      },
      error: () => {
        this.notificator.showError(
          'Erro no cadastro!',
          'Ocorreu um erro ao cadastrar o funcionário'
        );
      }
    })
  }

  employeeUpdate() {
    const payload: IFuncionarioEditPayload = updatePayload(this.form.value, this.personAndUserIds);

    this.employeeService.updateEmployee(payload).subscribe({
      complete: () => {
        this.notificator.showInfo(
          'Alterações salvas!',
          null
        );
        this.funcionarioNavigateService.setEmployeeId(payload.id)
        this.location.back();
      },
      error: () => {
        this.notificator.showError(
          'Erro ao salvar!',
          null
        );
      }
    })
  }

  /**
   * Chama a modal para troca do e-mail de um funcionário cadastrado.
   */
  handleChangeEmail() {
    this.activeModal = ActiveModal.EMAIL;
    this.modalService.showModal({
      icon: 'fa-regular fa-envelope',
      title: 'Alteração de e-mail',
      messageModal:
      `Uma mensagem de confirmação será enviada para o novo e-mail do funcionário. <br><br>
      Novo e-mail*:`,
      btnTitlePositive: 'Alterar',
      btnTitleNegative: 'Cancelar',
      close: false,
      inputConfig: {
        show: true,
        placeholder: 'mario@email.com',
        eyeIcon: false,
      },
      positiveCallback: () =>
      this.newEmail?.length
        ? this.changeEmail()
        : this.modalService.handleInputErrors(['Campo obrigatório']),
    });
  }

  /**
   * Atualiza o formulário com o novo email alterado
   */
  updateEmailExchange() {
    this.form.patchValue({
      email: this.newEmail
    })
  }

  /**
   * Realiza a requisição para a mudança de e-mail do funcionário
   */
  changeEmail() {
    this.previousValue.email = this.newEmail;
    const payload = updatePayload(this.previousValue, this.personAndUserIds);
    const regex = new RegExp(this.emailRegex)

    if (this.newEmail?.length && !regex.test(this.newEmail)) {
      this.modalService.handleInputErrors(['Formato de e-mail inválido']);
    } else {
      this.modalService.cleanInputErrors();

      this.modalService.setLoading(true);
      this.employeeService.updateEmployee(payload).subscribe({
        complete: () => {
          this.notificator.showInfo(
            'Alterações salvas!',
            'Confirmação de mudança de e-mail enviada!'
          );
          this.modalService.setLoading(false);
          this.updateEmailExchange();
          this.modalService.handleCloseModal();
        },
        error: ({error}) => {
          this.modalService.setLoading(false);
          if(error.status === 400) {
            this.modalService.handleInputErrors(['E-mail já cadastrado']);
          } else {
            this.notificator.showError(
              'Erro ao salvar!',
              null
            );
          }
        }
      })
    }
  }

  /**
   * CASE: Apenas edição sem alteração no status do funcionário
   */
  onlyEdit() {
    this.modalService.getCheckbox().subscribe({next: (data) => this.editCheckboxValue = data})
    if(this.hasChange && !this.editCheckboxValue) {
      this.modalService.showModal({
        icon: 'fa-regular fa-pen-to-square',
        title: 'Salvar edição',
        messageModal: 'Deseja salvar as alterações realizadas no perfil deste funcionário?',
        btnTitlePositive: 'Salvar',
        btnTitleNegative: 'Cancelar',
        checkbox: true,
        positiveCallback: () => this.employeeUpdate()
      });
    } else {
      this.employeeUpdate()
    }
  }

  /**
   * CASE: Edita o funcionário e o ativa
   */
  editWithActivation() {
    this.modalService.getCheckbox().subscribe({next: (data) => this.editCheckboxValue = data})
    if(this.hasChange && !this.editCheckboxValue) {
      this.modalService.showModal({
        icon: 'fa-regular fa-toggle-large-off',
        title: 'Ativar funcionário',
        messageModal: `Deseja mesmo alterar a situação de ${this.form.get('name')?.value} para ativo?`,
        btnTitlePositive: 'Ativar',
        btnTitleNegative: 'Cancelar',
        checkbox: true,
        positiveCallback: () => this.employeeUpdate()
      });
    } else {
      this.employeeUpdate()
    }
  }

  /**
   * CASE: Edita o funcionário e o inativa
   */
  editWithInactivation() {
    this.modalService.getCheckbox().subscribe({next: (data) => this.editCheckboxValue = data})
    if(this.hasChange && !this.editCheckboxValue) {
      this.modalService.showModal({
        icon: 'fa-regular fa-circle-xmark',
        title: 'Inativar funcionário',
        messageModal: `Deseja mesmo inativar o operador <b>${this.form.get('name')?.value}</b>?`,
        btnTitlePositive: 'Inativar',
        btnTitleNegative: 'Cancelar',
        checkbox: true,
        positiveCallback: () => this.employeeUpdate()
      });
    } else {
      this.employeeUpdate()
    }
  }

  /**
   * Controla todos os casos de salvamento numa edição de funcionário
   */
  editCases() {
    if(this.previousValue.status !== this.form.get('status').value) {
      if(this.form.get('status').value === true) {
        this.editWithActivation(); //ativando funcionário
      } else {
        this.editWithInactivation(); //inativando funcionário
      }
    } else {
      this.onlyEdit();
    }
  }

  /**
   * Envia os dados de cadastro do funcionário ao back-end
   */
  handleSubmit():void  {
    this.updateImage();
    this.markFieldsAsTouched(this.form);
    if(this.form.status === 'VALID') {
      if(this.isEdicao) {
        this.editCases();
      } else {
        this.modalService.showModal({
          icon: 'fa-regular fa-clipboard-user',
          title: 'Adicionar funcionário',
          messageModal: this.getRegisterModalText(),
          btnTitlePositive: this.form.get('status')?.value ? 'Ativar' :  'Continuar',
          btnTitleNegative: 'Cancelar',
          positiveCallback: () => this.employeeRegister()
        });
      }
    }
  }

}

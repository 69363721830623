import { Component, Input, OnInit } from "@angular/core";
import { IOperadorData } from "app/modulos/produtividade-beta/interfaces";
@Component({
  selector: 'app-header-operador',
  templateUrl: './header-operador.component.html',
  styleUrls: ['./header-operador.component.scss']
})
export class HeaderOperadorComponent implements OnInit {

  @Input() operador: IOperadorData;
  @Input() surveyTitle: string;


  constructor() {}

  ngOnInit(): void {}

}

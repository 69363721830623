import { Injectable } from "@angular/core";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { RequestService } from "app/servico/request.service";
import { Entrevista } from "../model/entrevista";
// tslint:disable-next-line:max-line-length
import { HttpResponse } from "@angular/common/http";
import { DadosPaginacao } from "app/util/componente/paginacao/dadosPaginacao";
import { PalavraChave } from "app/util/componente/tabela/tabela-filtravel/filter/palavraChave";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { EntrevistaRejeicao } from "../model/entrevistaRejeicao";
import { SortingOptions } from "../model/sortingOptions";
import { EntrevistaPerguntas } from "../resposta-pergunta-listagem/resposta-pergunta-listagem-tabela/model/entrevistaPerguntas";
import { RespostaPergunta } from "../resposta-pergunta-listagem/resposta-pergunta-listagem-tabela/model/respostaPergunta";
import { TipoExportacao } from "../tipoExportacao";


@Injectable({
  providedIn: "root",
})
export class EntrevistaService {

  public baseUrl = apiLocation;

  constructor(protected requestService: RequestService) { }

  /**
   * Calcula o número total de entrevistas resultantes de uma busca
   * pelas palavras chaves passadas por parâmetro.
   *
   * @param palavraChave palavras chaves da busca
   */
  getTotalEntidades(
    idPesquisa: number,
    palavraChave: PalavraChave
  ): Observable<number> {
    const uri = `${apiLocation}/pesquisas/${idPesquisa}/entrevistas/total`;

    const request: Observable<Object> = this.requestService.post(
      uri,
      palavraChave
    );

    return request.pipe(
      map((data) => {
        return <number>data;
      })
    );
  }

  /**
   * Realiza uma busca por palavras chaves dentro das entrevistas da
   * pesquisa identificada por idPesquisa. Também ordena os resultados da busca
   * de acordo com o SortingOptions passado.
   *
   * @param idPesquisa identificador da pesquisa
   * @param palavrasChave conjunto de palavras chaves da busca
   * @param dadosPaginacao opções de paginação
   * @param sortingOptions opções de ordenação
   */
  aplicarFiltro(
    idPesquisa: number,
    palavrasChave: PalavraChave,
    // tslint:disable-next-line:max-line-length
    dadosPaginacao?: DadosPaginacao,
    sortingOptions?: SortingOptions[]
  ): Observable<Entrevista[]> {
    const uri = `${apiLocation}/pesquisas/${idPesquisa}/entrevistas`;

    const sort = sortingOptions?.map((s) => s.toString());

    let sortQParam = {};
    if (sortingOptions?.length > 0) {
      sortQParam = {
        sort,
      };
    }

    // tslint:disable-next-line:max-line-length
    const request: Observable<
      HttpResponse<Object> | any
    > = this.requestService.post(
      uri,
      palavrasChave,
      false,
      dadosPaginacao,
      sortQParam
    );
    return request.pipe(
      map((response) => {
        const body: Entrevista[] = response.body;
        return body;
      })
    );
  }

  /**
   * Seta a entrevista como auditada ou não-auditada de acordo
   * com o status passado.
   *
   * @param idEntrevista identificador da entrevista
   * @param status valor booleano indicando se a entrevista foi
   * auditada ou não.
   */
  atualizarAudicao(idEntrevista: number, status: boolean): Observable<any> {
    const uri = `${apiLocation}/entrevista/${idEntrevista}/audicao`;
    const body = {
      status,
    };
    return this.requestService.patch(uri, body);
  }

  /**
   * Trás informações detalhadas da entrevista baseada no identificador da mesma
   *
   * @param idEntrevista identificador da entrevista
   */
  buscarEntrevista(idEntrevista: number): Observable<EntrevistaPerguntas> {
    const uri = `${apiLocation}/resposta-pesquisa/${idEntrevista}`;
    return this.requestService.get(uri).pipe(
      map((entrevistas: EntrevistaPerguntas) => {
        // const entrevistas = mockEntrevistaPerguntas.data();

        const respostaPerguntas = entrevistas.respostasPerguntas.map(
          (res, index) => {
            return new RespostaPergunta(
              res.pergunta,
              res.alternativasSelecionadas,
              index + 1
            );
          }
        );

        return new EntrevistaPerguntas(
          entrevistas.audio,
          respostaPerguntas,
          entrevistas.auditada,
          //entrevistas.causaRejeicao,
          entrevistas.causaRejeicao ? entrevistas.causaRejeicao : "Causa da rejeição indisponível no momento",
          entrevistas.rejeitada
        );
      })
    );
  }

  /**
   * Rejeita uma entrevista (de acordo com o id), salvando a causa da rejeição.
   *
   * @param idEntrevista identificador da entrevista
   * @param causa texto justificando a rejeição da entrevista
   */
  rejeitarEntrevista(
    idEntrevista: number,
    causa: string
  ): Observable<EntrevistaRejeicao> {
    const uri = `${apiLocation}/entrevista/${idEntrevista}/rejeicao`;
    const body = {
      causa,
    };
    return <Observable<EntrevistaRejeicao>>this.requestService.post(uri, body);
  }

  /**
   * Remove as entrevistas de acordo com os identificadores
   * passados por parâmetro.
   *
   * @param idsEntrevistas identificadores das entrevistas a serem removidas
   */
  removerEntrevistas(idsEntrevistas: string[]): Observable<boolean> {
    const uri = `${apiLocation}/entrevista`;
    return this.requestService.delete(uri, idsEntrevistas);
  }

  atualizarEntrevista(
    idEntrevista: number,
    respostasPerguntas: any[]
  ): Observable<any> {
    const uri = `${apiLocation}/entrevista/${idEntrevista}/respostas`;

    return this.requestService.patch(uri, respostasPerguntas);
  }

  aprovarEntrevista(idEntrevista: number): Observable<any> {
    const uri = `${apiLocation}/entrevista/${idEntrevista}/aprovar`;

    return this.requestService.put(uri);
  }

  downloadGravacoes(idEntrevistas: number[]): Observable<any> {
    const ids = idEntrevistas
      .map((i) => i.toString())
      .reduce((prev, next) => prev.concat(",").concat(next));

    const uri = `${apiLocation}/entrevista/gravacoes?entrevistas=${ids}`;

    /*const queryParams = {
      entrevistas: idEntrevistas,
    };*/

    return this.requestService.getBlob(uri);
  }

  exportarEmCSV(
    idPesquisa: number,
    tipoExportacao: string,
    idsEntrevistas: number[]
  ): Subject<boolean> {
    /**
     * Formato hardcoded pois no momento, só existe essa opção de exportação para auditoria
     * Quando forem solicitados novos formatos é importante criar um ENUM e um novo parâmetro para este metódo.
     */
    let uri = `${apiLocation}/pesquisas/${idPesquisa}/entrevistas/exportar?tipo=${tipoExportacao}&formato=CSV`;

    const showLoadingSubject: Subject<boolean> = new ReplaySubject();
    showLoadingSubject.next(true);

    if (idsEntrevistas.length > 0) {
    const idsEntrevistasStr = idsEntrevistas
      .map((i) => i.toString())
      .reduce((prev, next) => prev.concat(",").concat(next));

    uri = uri.concat(`&idsEntrevistas=${idsEntrevistasStr}`);
  }

    this.requestService.getBlob(uri).subscribe({
      next: (response: HttpResponse<any>) => {
        const blob = new Blob([response.body], {
          type: response.headers.get("Content-Type"),
        });

        const contentDisposition = response.headers.get("Content-Disposition");

        let fileName = "entrevistas";

        const warnMessage = `
          A resposta à requisição de exportação
          das entrevistas em formato CSV não definiu
          um nome para o arquivo para download. Verificar
          no servidor se o cabeçalho "Content-Disposition=attachment;filename=FILE_NAME"
          está sendo definido de forma correta.
        `;

        // regex com o pattern: {qualquer_string}filename={NOME_DO_ARQUIVO}
        const contentDispositionPattern = /.*filename=([\s\S]*)/;

        // nenhum content-disposition foi definido ou não está no formato esperado
        if (
          !contentDisposition ||
          !contentDispositionPattern.test(contentDisposition)
        ) {
          console.warn(warnMessage);
        } else {
          const groups = contentDispositionPattern.exec(contentDisposition);

          if (groups.length === 1) {
            console.warn(warnMessage);
          }

          fileName = groups[1].trim();
        }

        const url = window.URL.createObjectURL(blob);
        const aElement = document.createElement("a");
        document.body.appendChild(aElement);
        aElement.href = url;
        aElement.download = fileName;
        aElement.click();
        window.URL.revokeObjectURL(url);
        aElement.remove();

        showLoadingSubject.next(false);
        // tslint:disable-next-line:align
      },
      error: (error) => {
        showLoadingSubject.next(false);
        throw new Error(`Erro no download do conteúdo exposto na uri ${uri}`);
      },
    });

    return showLoadingSubject;
  }
}

<div class="table-pagination-container">
  <p>Items por página 10</p>

  <p *ngIf="canClickNextPage">
    {{ startOf }} - {{ oneOf }} de {{ totalOfLocalidades }}
  </p>
  <p *ngIf="!canClickNextPage">
    {{ startOf }} - {{ totalOfLocalidades }} de {{ totalOfLocalidades }}
  </p>

  <div class="page-controlls">
    <button
      type="button"
      (click)="handlePreviousPage()"
      [ngClass]="startOf > 10 && 'hasWhereToGo'"
      [disabled]="!canClickPreviousPage"
    >
      <i class="fas fa-angle-left"></i>
    </button>
    <button
      type="button"
      (click)="handleNextPage()"
      [ngClass]="canClickNextPage && 'hasWhereToGo'"
      [disabled]="!canClickNextPage"
    >
      <i class="fas fa-angle-right"></i>
    </button>
  </div>
</div>

import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  SelectDataItem,
  SelectedItemEvent,
} from "app/componentes/filterable-select/filterable-select-component";
import {
  EGroupType,
  ICitation,
  ICitationAndGroupResponse,
  IGroup,
  IRemoveAnswerOrChild,
  ISelectedAvulsesAndGroup,
} from "app/modulos/auditoria-beta/interfaces/audit-open-answer";
import { IQuestionOpenAnswer } from "app/modulos/auditoria-beta/interfaces/open-answers";
import { AuditoriaBetaService } from "app/modulos/auditoria-beta/services/auditoria-beta.service";
import { AutomatchService } from "app/modulos/auditoria-beta/services/consolidacao/automatch.service";
import { ConsolidationService } from "app/modulos/auditoria-beta/services/consolidacao/consolidation.service";
import { MatchOperationsService } from "app/modulos/auditoria-beta/services/consolidacao/match-operations.service";
import { MatchPersistenceService } from "app/modulos/auditoria-beta/services/consolidacao/match-persistance.service";
import { getIndexByList } from "app/modulos/auditoria-beta/utils/getIndexByArray";
import {
  parseAvulseQuote,
  parseConsolidationData,
} from "app/modulos/auditoria-beta/utils/parsers/consolidacaoParser";
import { parseQuestionToAccordenModel } from "app/modulos/auditoria-beta/utils/parsers/openAnswers";
import { NotificatorService } from "app/notificador/notificator.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";

@Component({
  selector: "app-open-answers",
  templateUrl: "./open-answers.component.html",
  styleUrls: ["./open-answers.component.scss"],
})
export class OpenAnswersComponent implements OnInit, OnDestroy {
  groupArray: Array<IGroup> = [];
  avulsesArray: Array<ICitation> = [];
  surveyId: number = null;
  requestCompleted: boolean = false;

  messageContent = "Selecione uma pergunta para iniciar a análise";
  selectedQuestionId: number = null;
  selectedAvulsesAndGroups: ISelectedAvulsesAndGroup = null;

  constructor(
    private notificatorService: NotificatorService,
    private auditoriaService: AuditoriaBetaService,
    private route: ActivatedRoute,
    private automatchService: AutomatchService,
    private matchOperationsService: MatchOperationsService,
    private matchPersistenceService: MatchPersistenceService,
    private consolidationService: ConsolidationService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  questionData: SelectDataItem[] = [];

  ngOnInit(): void {
    this.surveyId = this.route.snapshot.params.id;
    this.getQuestions();

    this.matchOperationsService.initSubscriptions({
      onUpdateAvulseTitle: this.editAvulseTitle.bind(this),
      onUpdateGroupTitle: this.editGroupTitle.bind(this),
      onUpdateSelectedCitationAndGroup: this.selectAvulsesAndGroup.bind(this),
    });
  }

  ngOnDestroy(): void {
    this.matchOperationsService.unsubscribe();
  }

  // Recupera as perguntas da pesquisa
  getQuestions(): void {
    this.auditoriaService.getSurveyQuestions(this.surveyId).subscribe({
      next: (data: IQuestionOpenAnswer[]) => {
        this.questionData = parseQuestionToAccordenModel(data);
      },
      error: (error) => {
        this.errorHandlerService.handleError(error, 'Erro ao recuperar as perguntas da pesquisa');
      },
    });
  }

  // Recupera as citações avulsas e consolidações
  getAvulsesAndConsolidations(): void {
    this.auditoriaService
      .getAvulsesAndConsolidations(this.surveyId, this.selectedQuestionId)
      .subscribe({
        next: (data: ICitationAndGroupResponse) => {
          this.avulsesArray = parseAvulseQuote(data.avulsas);
          this.groupArray = parseConsolidationData(data.consolidacoes);
        },
        error: (err) => {
          this.errorHandlerService.handleError(err, 'Erro ao recuperar as respostas');
        },
        complete: () => {
          this.requestCompleted = true;
          this.autoMatchAvulses();
        },
      });
  }

  // Muda a pergunta da pesquisa
  changeSelectedItem(event: SelectedItemEvent): void {
    this.selectedQuestionId = event.item.value;
    this.getAvulsesAndConsolidations();
  }

  // Obtem os grupos e/ou respostas que estão selecionados
  selectAvulsesAndGroup(selectedItems: ISelectedAvulsesAndGroup): void {
    if (selectedItems) {
      this.selectedAvulsesAndGroups = selectedItems;
    }
  }

  // Salva a alteração do titulo de uma alternativa em seguida executa o algoritimo de consolidação automática
  editAvulseTitle(data): void {
    if (data && this.requestCompleted) {
      const editingIndex = getIndexByList(data.id, this.avulsesArray);
      this.avulsesArray[editingIndex].title = data.newTitle;
      this.autoMatchAvulses();
    }
  }

  // Salva a alteração do titulo de um grupo
  editGroupTitle(data): void {
    if (data && this.requestCompleted) {
      const editingIndex = getIndexByList(data.id, this.groupArray);
      this.groupArray[editingIndex].title = data.newTitle;
    }
  }

  // Cria uma nova consolidação MANUAL a partir de respostas e/ou grupos de respostas e persiste no back-end
  matchCitationAndGroup(group: IGroup): void {
    if (this.selectedAvulsesAndGroups) {
      const newGroup = this.consolidationService.processManualMatch(group, this.selectedAvulsesAndGroups);
      this.groupArray = this.matchOperationsService.removeGroups(this.groupArray, this.selectedAvulsesAndGroups);
      this.groupArray.push({ ...newGroup });
      this.avulsesArray = this.matchOperationsService.removeAvulseMatchedWithinGroups(
        this.avulsesArray,
        this.groupArray
      );
      this.matchPersistenceService.getAndPersistUserConsolidation(
        { ...newGroup },
        this.groupArray,
        this.surveyId,
        this.selectedQuestionId
      ).then(() => {
        this.getAvulsesAndConsolidations();
      })
    }
  }

  // Cria uma consolidação automática e persiste no back-end
  autoMatchAvulses(): void {
    const { groups, avulses } = this.automatchService.processAutoMatch(
      this.avulsesArray,
      this.groupArray
    );
    this.groupArray = groups;
    this.avulsesArray = avulses;
    const automatchGroups = this.automatchService.getAutoMatchGroups(this.groupArray);
    if (automatchGroups.length) {
      this.matchPersistenceService.getAndPersistConsolidation(
        automatchGroups,
        this.groupArray,
        this.surveyId,
        this.selectedQuestionId
      );
    }
  }

  /**
   * Remove uma citação ou consolidação de um grupo e dispara uma flash message e em seguida executa o algoritimo de consolidação automática
   * @param {idAnswer, idGroup, idChildGroup}: ids das estruturas que serão removidas
   */
  handleRemoveCitationOrConsolidation({
    idAnswer,
    idGroup,
    idChildGroup,
  }: IRemoveAnswerOrChild): void {
    const groupIndex = getIndexByList(idGroup, this.groupArray);
    const editingGroup = { ...this.groupArray[groupIndex] };

    if (!!idChildGroup) {
      this.removeChildGroup(idChildGroup, editingGroup);
    } else {
      this.removeCitation(idAnswer, editingGroup);
    }

    // remove o grupo se não houver mais respostas ou filhas dentro
    if (editingGroup.citation.length < 2 && !editingGroup.groups.length) {
      this.groupArray.splice(groupIndex, 1);
      this.notificatorService.showInfo(
        "Grupo desfeito",
        "Devido a falta de citações o grupo foi desfeito"
      );
    }
    this.autoMatchAvulses();
  }

  /**
   * Responsável pela lógica de remoção da consolidação filha
   */
  removeChildGroup(idChildGroup: number | string, editingGroup: IGroup): void {
    const childGroupIndex = getIndexByList(idChildGroup, editingGroup.citation);
    const [removeChildGroup] = editingGroup.groups.splice(childGroupIndex, 1);
    this.groupArray.push(removeChildGroup as IGroup);

    this.notificatorService.showInfo(
      "Consolidação removida",
      "A consolidação retornou para lista"
    );
  }

  /**
   * Responsável pela lógica de remoção da citação
   */
  removeCitation(idAnswer: number | string, editingGroup: IGroup): void {
    const citationIndex = getIndexByList(idAnswer, editingGroup.citation);
    const [removedCitation] = editingGroup.citation.splice(citationIndex, 1);

    this.avulsesArray.push(removedCitation);

    this.notificatorService.showInfo(
      "Citação removida",
      "A citação retornou para lista de citações"
    );
  }

}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuditoriaObservationsModalService } from "app/componentes/audit-observations/modal-observations.service";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import {
  FilterIcon,
  FilterTypes,
} from "app/componentes/filter-select/constants";
import {
  FilterConfigs,
  SearchInputConfig,
} from "app/util/componente/genericTable/interfaces/filters";
import { TableHead } from "app/util/componente/genericTable/interfaces/tHead";
import { TableRow } from "app/util/componente/genericTable/interfaces/tRow";
import {
  TableEvents,
  TableGlobalConfig,
} from "app/util/componente/genericTable/interfaces/table";
import {
  iconCellFactory,
  statusCellFactory,
  textCellFactory,
} from "app/util/componente/genericTable/utils/cellFactory";
import { tHeadFactory } from "app/util/componente/genericTable/utils/tHeadFactory";
import { ModalData } from "app/util/componente/prompt-modal/prompt-modal.component";
import { IOperadorData } from "../interfaces";

@Component({
  selector: "app-detalhes-operador",
  templateUrl: "./detalhes-operador.component.html",
  styleUrls: ["./detalhes-operador.component.scss"],
})
export class DetalhesOperadorComponent implements OnInit {
  surveyId: number;
  collectionId: number;

  constructor(
    private route: ActivatedRoute,
    private observationsModalService: AuditoriaObservationsModalService
  ) {
    this.surveyId = +this.route.snapshot.paramMap.get("surveyId");
  }

  ngOnInit(): void {
    this.initObservationsModalData();
  }

  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Produtividade",
      itemLink: "/produtividade-beta",
      active: false,
    },
    {
      itemName: "Avaliação da Pandemia pelo Governo Federal do estado do Piauí",
      itemLink: "/produtividade-beta/visao-geral/1/operadores/operador/1",
      active: true,
    },
  ];

  // Estado da modal de observações.
  observationsModal: ModalData = null;

  operador: IOperadorData = {
    id: 1,
    foto: { arquivo: "https://alfa-tensai-teste.s3.amazonaws.com/tensai/funcionario/5J9KLTDMBo_1.jpeg" },
    nome: "Joselino Cavalcanti",
    matricula: "024356",
    coletas: {
      cumpridas: 20,
      auditadas: 10,
      aprovadas: 8,
      reprovadas: 2,
    },
    dias_trabalhados: 2,
    avaliacao_geral: 8.3,
    tempo_medio: "00:02:20"
  };

  surveyTitle: string =
    "Avaliação da Pandemia pelo Governo Federal do estado do Piauí";

  tHeadOperadorColetas: TableHead[] = [
    tHeadFactory("ID").build(),
    tHeadFactory("Localidade")
      .build(),
    tHeadFactory("Data").build(),
    tHeadFactory("Início").build(),
    tHeadFactory("Fim").build(),
    tHeadFactory("Duração").build(),
    tHeadFactory("Situação").build(),
    tHeadFactory("Observações")
      .cellAlign('center')
      .cellStyles((cs) => {
        if (cs.metadata === 0) {
          return {
            color: "var(--gray200)",
            "pointer-events": "none",
          };
        } else {
          return null;
        }
      })
      .build(),
  ];

  tBodyOperadorColetas: TableRow[] = [
    {
      id: 1,
      cells: [
        textCellFactory("1").build(),
        textCellFactory("Conjunto Habitacional Fazenda Maria").build(),
        textCellFactory("14/07/23").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("1min 30s").build(),
        statusCellFactory("var(--status-error)", "Reprovada", "").build(),
        iconCellFactory(["fa-regular fa-message-lines"], "button")
          .metadata(1)
          .build(),
      ],
    },
    {
      id: 2,
      cells: [
        textCellFactory("2").build(),
        textCellFactory("Conjunto Habitacional Fazenda Maria").build(),
        textCellFactory("14/07/23").build(),
        textCellFactory("10:03:30").class("vaidatudocerto").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("1min 30s").build(),
        statusCellFactory("var(--status-done)", "Aprovada", "").build(),
        iconCellFactory(["fa-regular fa-message-lines"], "button")
          .metadata(0)
          .build(),
      ],
    },
    {
      id: 3,
      cells: [
        textCellFactory("3").build(),
        textCellFactory("Conjunto Habitacional Fazenda Maria").build(),
        textCellFactory("14/07/23").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("1min 30s").build(),
        statusCellFactory("var(--gray200)", "Não auditada", "").build(),
        iconCellFactory(["fa-regular fa-message-lines"], "button")
          .metadata(1)
          .build(),
      ],
    },
    {
      id: 5,
      cells: [
        textCellFactory("5").build(),
        textCellFactory("Conjunto Habitacional Fazenda Maria").build(),
        textCellFactory("14/07/23").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("1min 30s").build(),
        statusCellFactory("var(--gray200)", "Não auditada", "").build(),
        iconCellFactory(["fa-regular fa-message-lines"], "button")
          .metadata(1)
          .build(),
      ],
    },
  ];

  tableConfig: TableGlobalConfig = {
    headerHeight: "60px",
    colWidths: ["0.06", "0.25", "0.09", "0.09", "0.09", "0.1", "0.19", "0.13"],
    rowStyles: {
      fontSize: "14px",
    },
  };

  tableFilter: FilterConfigs[] = [
    {
      type: FilterTypes.CHECK,
      placeholder: "Situação",
      icon: FilterIcon.FUNNEL,
      options: [
        {
          id: 1,
          label: "Aprovada",
        },
        {
          id: 2,
          label: "Reprovada",
        },
        {
          id: 3,
          label: "Não auditada",
        },
      ],
    },
    {
      type: FilterTypes.RANGE_DATE,
      placeholder: "Data",
      icon: FilterIcon.CALENDAR,
      selectConfig: {
        intervalDate: {
          endDate: new Date("2023-05-01"),
          startDate: new Date("2015-05-01"),
        },
      },
    },
    {
      type: FilterTypes.RADIO,
      placeholder: "Ordenar",
      icon: FilterIcon.SORTING,
      options: [
        {
          id: 1,
          label: "Coletas mais recentes",
        },
        {
          id: 2,
          label: "Coletas mais antigas",
        },
        {
          id: 3,
          label: "Maior duração",
        },
        {
          id: 4,
          label: "Menor duração",
        },
      ],
    },
  ];

  searchConfig: SearchInputConfig = {
    placeholder: "Busque por Id ou localidade",
  };

  initObservationsModalData() {
    this.observationsModal = this.observationsModalService.modalData;
  }

  // eventos da tabela
  handleTableEvents(event: TableEvents): void {
    if (event.eventType === "CELL_ICON_CLICK") {
      this.observationsModal.show = true
      this.collectionId = +event.rowId;
    }
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ICardData } from "../../visao-geral.component";

@Component({
  selector: "app-card-info-media",
  templateUrl: "./card-info-media.component.html",
  styleUrls: ["./card-info-media.component.scss"],
})
export class CardInfoMediaComponent implements OnInit {
  @Input() title: string;
  @Input() infoTitle: string;
  @Input() infoValue: string;
  @Input() type = "coletas_por_dia" || "aprovacoes" || "tempo_medio_coleta";
  @Input() surveyId: number | string

  @Input() data: ICardData[] = [];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  // formatar a hora da coleta
  formatterTimer(timer: string): string {
    const timerParts = timer.split(":");

    const hours = parseInt(timerParts[0]);
    const minutes = parseInt(timerParts[1]);
    const seconds = parseInt(timerParts[2]);

    const formatterTimer = `${hours ? hours : ""}${
      minutes < 10 ? `0${minutes}` : minutes
    }min${seconds < 10 ? `0${seconds}` : seconds}s`;

    return formatterTimer;
  }

  roundNumber(number: number): number {
    return Math.round(number)
  }

  goDetails(id: number): void {
    this.router.navigate([`/produtividade-beta/visao-geral/${this.surveyId}/operadores/operador/${id}`])
  }
}

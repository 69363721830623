import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { getAndFormatTime } from '../../utils/parsers/getAndFormatTime';
import { interval } from 'rxjs';
import { AudioPlayerService } from './audit-audio-player.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-audit-audio-player',
  templateUrl: './audit-audio-player.component.html',
  styleUrls: ['./audit-audio-player.component.scss'],
})
export class AuditAudioPlayerComponent implements OnInit, OnChanges {

@ViewChild('audioPlayer') audioPlayerRef: ElementRef<HTMLAudioElement>;

  @Input() audioToPlay: string; // Audio path to reproduce
  audioIsPlaying: boolean = false; // Controls audio playback

  // Sound values
  currentTime: number = 0;
  durationTime: number= 0;

  // Sound time control attributes
  currentTimeDisplay: string = '00:00';
  audioDurationDisplay: string = '00:00';
  interval: any;

  // Speed control attributes
  speedOptions = [2, 1.5, 1]; // Speed options
  audioSpeed = 1; // represents the current speed (default: 1)
  showSpeedOptions = false; // Variável para controlar a exibição das opções de velocidade

  // Volume control attributes
  isMuted: boolean = false; // Audio is muted = true | else = false
  showVolume: boolean = false; // Controls the audio volume
  volumeValue: number = 1; // default value 1 = max value

  showDownloadTooltip: boolean = false;

  audioHeard: boolean = false; // Controls whether the user has heard the entire audio

  audioPlaySubscription: Subscription;

  constructor(private audioPlayerService: AudioPlayerService) { }

  ngOnInit(): void {
    // subscriptions
    this.audioPlaySubscription = this.audioPlayerService.audioPlay$.subscribe((isPlaying) => {
      this.audioIsPlaying = isPlaying;

      if (this.audioIsPlaying) {
        this.playAudio();
      } else {
        this.pauseAudio();
      }
    });
  
    // Updates interval attribute every one second
    interval(1000).subscribe(() => {
      this.updateCurrentTime();
      this.getRemainingTime();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.audioToPlay && !!changes.audioToPlay.currentValue) {
      this.setAudioSource();
    }
  }

  setAudioSource() {
    if (this.audioPlayerRef.nativeElement) {
      this.audioPlayerRef.nativeElement.src = this.audioToPlay;
    }
  }

  onLoad(event) {
    this.durationTime = Math.round(event.srcElement.duration);
  }

  ngOnDestroy(): void {
    // Clear gap interval attriube component is destroyed
    clearInterval(this.interval);
    // unsubscribe service observable
    this.audioPlaySubscription.unsubscribe();
    // reset to default state
    this.audioPlayerService.reset();
  }

  /**
   * Start audio playback
   */
  playAudio(): void {
    this.audioPlayerRef.nativeElement.play();
  }

  /**
   * pause audio playback
   */
  pauseAudio(): void {
    this.audioPlayerRef.nativeElement.pause();
  }

  /**
   * controls user action to start or pause the music
   */
  handleToggleAudio(): void {
    if (this.audioPlayerService.isPlaying) {
      this.audioPlayerService.pauseAudio();
    } else {
      this.audioPlayerService.playAudio();
    }
  }

  /**
   * Controls the audio progress bar
   */
  progressBarControl(event): void {
    const value = event.target.value;
    this.currentTime = value
    this.audioPlayerRef.nativeElement.currentTime = value;
  }

  /**
   * Advance the audio in 5 seconds
  */
  handleAdvanceAudio(): void {
    this.audioPlayerRef.nativeElement.currentTime += 5;
  }

  /**
   * Rewind the audio in 5 seconds
  */
  handleRewindAudio(): void {
    this.audioPlayerRef.nativeElement.currentTime -= 5;
  }


  /**
   * Controls the display of the speed menu
   */
  toggleSpeedOptions(): void {
    this.showSpeedOptions = !this.showSpeedOptions;
  }


  /**
   * Set a new reproduction speed
   * @param speed: speedOptions
   */
  handleAudioSpeed(speed: number): void {
    this.audioSpeed = speed;
    this.audioPlayerRef.nativeElement.playbackRate = this.audioSpeed;
  }


  /**
   * Controls audio current time
  */

  updateCurrentTime(): void {
    const currentTimeInSeconds = Math.round(this.audioPlayerRef.nativeElement.currentTime);
    this.currentTime = currentTimeInSeconds;
    this.currentTimeDisplay = getAndFormatTime(currentTimeInSeconds);

    if(!this.audioHeard) {
      this.hasAlreadyHeardAudio();
    }
  }


  /**
   * Controls the time left until the audio ends
   */
  getRemainingTime(): void {
    const audioDurationInSeconds = Math.round(this.durationTime);
    const remainingTimeInSeconds = audioDurationInSeconds - Math.round(this.audioPlayerRef.nativeElement.currentTime);

    this.audioDurationDisplay =  getAndFormatTime(remainingTimeInSeconds);
  }


  /**
   * control if the audio is muted or not
   */
  toggleMute(): void {
    this.isMuted = !this.isMuted;
    this.audioPlayerRef.nativeElement.muted = this.isMuted;
  }

  /**
   * Controls the display of the volume menu
   */
  showVolumeControl(): void {
    this.showVolume = !this.showVolume;
  }

  /**
   * Controls audio volume
   */
  updateVolume(): void {
    this.audioPlayerRef.nativeElement.volume = this.volumeValue;
    this.isMuted = this.volumeValue === 0;
  }

  /**
   * Controls download tooltip visibility
   */
  handleShowDownloadTooltip(): void {
    this.showDownloadTooltip = !this.showDownloadTooltip;
  }


  /**
   * Controls if user has already heard the audio
   */
  hasAlreadyHeardAudio(): void {
    if(this.currentTime === this.durationTime) {
      this.audioHeard =  true;
    }
  }

}

<app-breadcrumb
  [data]="dataBreadcrumb"
  [goBackText]="'Voltar às pesquisas'"
></app-breadcrumb>

<main class="visao-geral-main">
  <app-header-produtividade
    [tituloHeader]="header.titulo"
    [item1]="header.periodo"
    [item2]="header.resumo"
    [progresso]="header.progresso"
  ></app-header-produtividade>

  <app-podium></app-podium>
  <div class="container-infos">
    <app-card-info-media
      title="Média de coletas por dia"
      infoTitle="Média geral:"
      infoValue="42 coletas"
      [surveyId]="surveyId"
      [type]="'coletas_por_dia'"
      [data]="formatterDataColetas()"
    ></app-card-info-media>
    <app-card-info-media
      title="Aprovação em auditoria"
      infoTitle="Média de aprovações:"
      infoValue="96%"
      [surveyId]="surveyId"
      [type]="'aprovacoes'"
      [data]="formatterDataAprovacoes()"
    ></app-card-info-media>
    <app-card-info-media
      title="Tempo médio de coleta"
      infoTitle="Média geral:"
      infoValue="10min20s"
      [surveyId]="surveyId"
      [type]="'tempo_medio_coleta'"
      [data]="formatterDataTempoMedio()"
    ></app-card-info-media>
  </div>
</main>


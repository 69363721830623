<header>
  <div class="operator-info">
    <img class="operator-image" [src]="operador.foto.arquivo" />
    <span>
      <h3>{{ operador.nome }}</h3>
      <p>Matrícula: {{ operador.matricula }}</p>
    </span>
  </div>

  <div class="survey-info">
    <h3>{{ surveyTitle }}</h3>
  </div>

  <div class="work-info">
    <h3>Dias trabalhados</h3>
    <p>{{ operador.dias_trabalhados }} dias</p>
  </div>

  <div class="svg-card">
    <svg width="389" height="135" viewBox="0 0 389 135" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="path-one" d="M153.814 47.7666C234.368 26.8289 330.888 48.3416 429.286 111.04C481.149 144.1 534.723 169.229 588.96 186.19L588.96 -79.7988L146.355 -79.7988C151.845 -37.5268 154.366 5.32808 153.81 47.7627L153.814 47.7666Z" fill="#610082" fill-opacity="0.1"/>
      <path class="path-two" d="M153.803 44.6855C149.82 102.429 119.903 155.553 69.221 190.911C47.5443 206.036 23.8107 216.864 -1.53442 223.43C-1.53044 223.025 -1.52249 222.625 -1.51851 222.22C2.79454 164.945 32.6476 112.317 82.9718 77.2047C104.648 62.079 128.382 51.2512 153.727 44.6855L153.803 44.6855Z" fill="#F4EDF7" fill-opacity="0.3"/>
    </svg>

  </div>
</header>

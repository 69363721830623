import { Component, Input, OnInit } from '@angular/core';
import { IOperadorData } from 'app/modulos/produtividade-beta/interfaces';
import dates from 'app/util/misc/dates';

@Component({
  selector: 'app-general-evaluation',
  templateUrl: './general-evaluation.component.html',
  styleUrls: ['./general-evaluation.component.scss']
})
export class GeneralEvaluationComponent implements OnInit {

  @Input() operador: IOperadorData;
  averageTime: string = ''

  constructor() { }

  ngOnInit(): void {
    this.averageTime = dates.timeFormatter(this.operador.tempo_medio);
  }

  setAssessmentStyle(): string {
    if (this.operador.avaliacao_geral > 7.0) {
      return 'status green-status'
    } else if (this.operador.avaliacao_geral > 5.0) {
      return 'status yellow-status'
    } else {
      return 'status red-status'
    }
  }

}

<div class="container-info-media">
  <h1>{{ title }}</h1>

  <div *ngIf="!!data" class="options">
    <div class="info-card">
      <p>{{ infoTitle }} <span>{{ infoValue }}</span></p>
    </div>

    <div class="options-list" [ngClass]="type">
      <div *ngFor="let operador of data; let i = index" [ngClass]="['item item-' + i]">
        <img *ngIf="operador.foto && type === 'coletas_por_dia'" [src]="operador.foto.arquivo" [alt]="operador.nome">
        <i *ngIf="!operador.foto && type === 'coletas_por_dia'" class="fa-duotone fa-user"></i>
        <i *ngIf="!operador.foto && type === 'aprovacoes'" class="fa-solid fa-star"></i>
        <i *ngIf="!operador.foto && type === 'tempo_medio_coleta'" class="fa-solid fa-crown"></i>
        <div *ngIf="type === 'aprovacoes'" class="progress">
          <div class="bar" [ngStyle]="{'width.%': operador.valor.toString().split('%')[0]}"></div>
        </div>
        <span>{{ i + 1}}</span>
        <button (click)="goDetails(+operador.id)" [disabled]="operador.nome === '-'">
          <p>{{operador.nome}}</p>
        </button>
        <div *ngIf="type === 'coletas_por_dia'" class="value">
          {{+operador.valor > 0 ? roundNumber(+operador.valor) : 0}} Coletas
        </div>
        <div *ngIf="type === 'aprovacoes'" class="value">{{operador.valor}}%</div>
        <div *ngIf="type === 'tempo_medio_coleta'" class="value">{{formatterTimer(operador.valor+'')}}</div>
      </div>
    </div>
  </div>

  <div *ngIf="!(!!data)" class="empty-card">
    <p>Ainda não existem auditorias</p>
    <p>nesta pesquisa</p>
  </div>
</div>

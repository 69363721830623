import { Injectable } from "@angular/core";
import { IInfoCardData } from "app/componentes/info-card/info-card.component";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { SurveyListOrderTypes, SurveyListSituations, SurveyListSortingAttributes } from "app/modulos/auditoria-beta/constant";
import { IFilterSurveyResults, ISurveyListResponse } from "app/modulos/auditoria-beta/interfaces";
import { PesquisaCadastro } from "app/modulos/pesquisa-old/cadastro/model/pesquisaCadastro";
import { RequestService } from "app/servico/request.service";
import { CrudService } from "app/servico/requestService/crudService";
import { Observable } from "rxjs";
import { IInfoCardPayload } from "../interfaces/listagem";

@Injectable({
  providedIn: "root",
})
export class ProdutividadeBetaService extends CrudService<PesquisaCadastro> {

  public baseUrl = this.resourcePath;

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}`);
  }

  filterSurveyBy(
    currentPage: number = 0,
    size: number = 10,
    sort?: SurveyListSortingAttributes | "",
    order?: SurveyListOrderTypes | "",
    keywords: string[] = [],
    status: SurveyListSituations[] = []
  ): Observable<ISurveyListResponse[]> {
    const pageParam = `page=${currentPage}`;
    const sizeParam = `&size=${size}`;
    const sortParam = sort ? `&sort=${sort}` : "";
    const directionParam = order ? `&direction=${order}` : "";

    const requestUrl = `${this.baseUrl}/pesquisas/auditorias/filtrar/palavra-chave?${pageParam}${sizeParam}${sortParam}${directionParam}`;

    const requestPayload = {
      palavrasChave: keywords,
      situacoes: status,
    };

    return this.requestService.post(requestUrl, requestPayload) as Observable<
      ISurveyListResponse[]
    >;
  }

   /**
   * Obtem o total de pesquisas cadastradas baseado no filtro informado
   * @param filters: filtros utiliizados pelo usuário
   * @returns Inteiro que representa o valor do total de pesquisas cadastradas
   */
   getTotalSurveys(filters: IFilterSurveyResults): Observable<number> {
    const requestUrl: string = `${this.baseUrl}/pesquisas/auditorias/filtrar/palavra-chave/total-registros`;

    const response = <Observable<number>>(
      this.requestService.post(requestUrl, filters)
    );
    return response;
  }

  /**
   * Retorna os dados do header
   */
  getInfoCardData(): Observable<IInfoCardPayload> {
    const requestUrl: string =  `${this.baseUrl}/pesquisas/auditorias/resumo`;
    const response = <Observable<IInfoCardPayload>>(
      this.requestService.get(requestUrl)
    );
    return response;
  }

}

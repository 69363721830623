<div [ngClass]="position === 1 ? 'default-card big-card' : 'default-card small-card'" *ngIf="defaultCard">
  <div class="default-image">
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_7051_2977)">
      <circle cx="44" cy="40" r="40" fill="var(--tertiary)"/>
      <circle cx="44" cy="40" r="39" stroke="var(--tertiary)" stroke-width="2"/>
      </g>
      <g clip-path="url(#clip0_7051_2977)">
        <path d="M35 48.8389C35 44.9912 38.1172 41.874 41.9648 41.874H45.5352C49.3828 41.874 52.5 44.9912 52.5 48.8389C52.5 49.4795 51.9805 49.999 51.3398 49.999H36.1602C35.5195 49.999 35 49.4795 35 48.8389Z" fill="var(--secondary)"/>
        <path d="M38.7495 35C38.7495 33.6739 39.2763 32.4021 40.214 31.4645C41.1517 30.5268 42.4234 30 43.7495 30C45.0756 30 46.3474 30.5268 47.285 31.4645C48.2227 32.4021 48.7495 33.6739 48.7495 35C48.7495 36.3261 48.2227 37.5979 47.285 38.5355C46.3474 39.4732 45.0756 40 43.7495 40C42.4234 40 41.1517 39.4732 40.214 38.5355C39.2763 37.5979 38.7495 36.3261 38.7495 35Z" fill="var(--secondary)" opacity="0.2"/>
      </g>
      <defs>
      <filter id="filter0_d_7051_2977" x="0" y="0" width="88" height="88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7051_2977"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7051_2977" result="shape"/>
      </filter>
      <clipPath id="clip0_7051_2977">
      <rect width="17.5" height="20" fill="white" transform="translate(35 30)"/>
      </clipPath>
      </defs>
    </svg>
  </div>
  <p>{{ position }}</p>
</div>

<div [ngClass]="position === 1 ? 'operator-card big-card' : 'operator-card small-card'" *ngIf="!defaultCard">
  <img class="operator-image" [src]="operadorData?.foto.arquivo" />
  <span class="medal" *ngIf="position === 1">
    <i class="fa-solid fa-star"></i>
  </span>
  <p class="position">{{ position }}</p>
  <div [ngClass]="position === 1 ? 'operator-wrapper' : ''">
    <span [ngClass]="defineAssessmentColor()">{{ operadorData?.avaliacao_geral }}</span>
    <a (click)="handleNavigate()">{{ operadorData?.nome }}</a>
  </div>
</div>

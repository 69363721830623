<div class="title-step-section">
  <div class="row">
    <div class="col-12 mt-5">
      <h3>1 de 2: Correção de respostas abertas</h3>
    </div>
  </div>
</div>
<div class="sg-input-group select-inline">
  <label>Selecione uma pergunta para edição das respostas</label>
  <app-pergunta-selector [idPesquisa]="idPesquisa" (inputUpdate)="onPerguntaSelecionada($event)">
  </app-pergunta-selector>
</div>
<div *ngIf="selecionado && totalPerguntasRespostasCitacoes > 0" class="row mt-5">
  <div class="col-lg-8 ">
    <div class="listCitacoes container-white">
      <app-pergunta-resposta-multi-selector [idPesquisa]="idPesquisa"
        [citacoes]="citacoes"
        [perguntaSelecionadaSubject]="perguntaSelecionadaSubject"
        [novaPesquisaSelecionadaSubject]="novaPesquisaSelecionadaSubject"
        (perguntaRespostaSelecionada)="onPerguntaRespostaSelecionada($event)">
      </app-pergunta-resposta-multi-selector>
    </div>
  </div>
  <div class="col-lg-4">
    <div class="container-white corrigeCitacoes">
      <app-resposta-edicao [perguntaRespostasSelecionadasSubject]="perguntaRespostasSelecionadasSubject"
        (respostaEditada)="onRespostaEditada($event)" [idPesquisa]="idPesquisa" [idPergunta]="idPergunta" [citacoes]="citacoes">
      </app-resposta-edicao>
    </div>
  </div>
</div>
<!-- MENSAGEM ERRO -->
<div class="msg-error" *ngIf="selecionado && totalPerguntasRespostasCitacoes == 0">
  <span>Não existem respostas a serem editadas</span>
</div>

import { Component, OnInit } from "@angular/core";
import { ChangePasswordService } from "app/componentes/change-password/change-password.service";
import { ClienteService } from "app/modulos/cliente/servico/cliente.service";
import { OperadorService } from "app/modulos/operador/servico/operador.service";
import { PesquisaCadastro } from "app/modulos/pesquisa-old/cadastro/model/pesquisaCadastro";
import { PesquisaService } from "app/modulos/pesquisa-old/servico/pesquisa.service";
import { LgpdSignatureService } from "app/servico/lgpdService/lgpd-signature.service";
import { PalavraChave } from "app/util/componente/tabela/tabela-filtravel/filter/palavraChave";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardBetaComponent implements OnInit {
  // dados de operadores
  totalOperadores: number = 0;
  totalOperadoresAtivos: number = 0;
  totalOperadoresInativos: number = 0;

  // dados de pesquisas
  totalPesquisaEmAndamento: number = 0;
  totalPesquisaEncerrada: number = 0;
  totalPesquisaRealizada: number = 0;

  // dados dos clientes
  totalClientes: number = 0;
  totalClientesAtivos: number = 0;
  totalClientesInativos: number = 0;

  // dados das entrevistas
  totalEntrevistaAuditada: number = 0;
  totalEntrevistaNaoAuditada: number = 0;
  totalEntrevistaRealizada: number = 0;

  // lista de pesquisas
  pesquisas: PesquisaCadastro[] = [];

  // loading
  isLoading: boolean = false;

  // variável que controla se todas as request já foram executadas
  allRequest: boolean = false;
  //
  flags = {
    needChangePassword: true,
    needSignDocuments: true,
  };

  constructor(
    private operadorService: OperadorService,
    private pesquisaService: PesquisaService,
    private clienteService: ClienteService,
    private changePasswordService: ChangePasswordService,
    private signatureService: LgpdSignatureService
  ) {}

  ngOnInit() {
    this.initFlags();
  }

  initFlags() {
    this.changePasswordService.changePassword.subscribe({
      next: (changePassword) => {
        this.flags.needChangePassword = changePassword;
        this.updateRequestsState();
      }
    });

    this.signatureService.needSignDocument.subscribe({
      next: (needSignDocuments: boolean) => {
        this.flags.needSignDocuments =  needSignDocuments;
        this.updateRequestsState();
      }
    })
  }

  updateRequestsState() {
    if (
      !this.flags.needChangePassword &&
      !this.flags.needSignDocuments &&
      !this.allRequest
    ) {
      this.handleAllRequest();
    }
  }

  // função que engloba todas as request do dashboard
  handleAllRequest() {
    this.allRequest = true;
    this.getPesquisas();
    this.getQtPesquisas();
    this.getOperadoresStatusAtivoOuInativos();
    this.getTotalClientesStatusAtivoOuInativos();
    this.getRespostasPesquisas();
  }

  /**
   * função para buscar status dos operadores
   */
  getOperadoresStatusAtivoOuInativos() {
    this.operadorService.getTotalOperadores().subscribe((dados) => {
      this.totalOperadoresAtivos = 0;
      this.totalOperadoresInativos = 0;

      this.totalOperadores = dados.length;

      dados.map((operador) => {
        if (operador.ativo) {
          this.totalOperadoresAtivos = this.totalOperadoresAtivos + 1;
        } else {
          this.totalOperadoresInativos = this.totalOperadoresInativos + 1;
        }
      });
    });
  }

  /**
   * função para buscar os status das pesquisas
   */
  getTotalPesquisas(statusList: string[]) {
    statusList.forEach((status) => {
      this.pesquisaService.getPesquisasByStatus(status).subscribe((dado) => {
        if (status === "EXECUCAO") {
          this.totalPesquisaEmAndamento = dado;
        }
        if (status === "CONCLUIDO" || status === "ARQUIVADO") {
          this.totalPesquisaEncerrada = this.totalPesquisaEncerrada + dado;
        }
      });
    });

    this.pesquisaService
      .getTotalEntidades(new PalavraChave())
      .subscribe((dado) => {
        this.totalPesquisaRealizada = dado;
      });
  }

  /**
   * recupera a quantidade de pesquisas em execução e concluídas
   */
  getQtPesquisas() {
    this.getTotalPesquisas(["EXECUCAO", "CONCLUIDO", "RASCUNHO"]);
  }

  /**
   * recupera as últimas pesquisas
   */
  getPesquisas() {
    this.isLoading = true;
    this.pesquisaService
      .buscarPorPalavrasChavesEStatus(
        { palavrasChave: [''] }, [], false, { page: 0, size: 8 }
      )
      .subscribe({
        next: (surveys) => {
          this.pesquisas = surveys;
          this.isLoading = false;
        },
        error: () => this.isLoading = false,
        complete: () => this.isLoading = false
      });
  }

  /**
   * função para buscar os status dos clientes
   */
  getTotalClientesStatusAtivoOuInativos() {
    this.clienteService.getTotalClientes().subscribe((dados) => {
      this.totalClientesAtivos = 0;
      this.totalClientesInativos = 0;
      this.totalClientes = dados.length;

      dados.map((cliente) => {
        if (cliente.ativo) {
          this.totalClientesAtivos = this.totalClientesAtivos + 1;
        } else {
          this.totalClientesInativos = this.totalClientesInativos + 1;
        }
      });
    });
  }

  /**
   * função para buscar os status das entrevistas
   */
  getRespostasPesquisas() {
    this.pesquisaService
      .getRespostaPesquisasByStatusAuditada()
      .subscribe((dados) => {
        const entrevistas = dados;

        entrevistas.filter((entrevista) => {
          if (entrevista.auditada) {
            this.totalEntrevistaAuditada = this.totalEntrevistaAuditada + 1;
          } else {
            this.totalEntrevistaNaoAuditada =
              this.totalEntrevistaNaoAuditada + 1;
          }
        });
        this.totalEntrevistaRealizada = entrevistas.length;
      });
  }
}

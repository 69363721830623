import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  IGroups,
} from "../../interfaces/cadastroFuncionario";

@Component({
  selector: "app-multiselect-group",
  templateUrl: "./multiselect-group.component.html",
  styleUrls: ["./multiselect-group.component.scss"],
})
export class MultiselectGroupComponent implements OnInit {
  @ViewChild("combobox", { static: true }) combobox: ElementRef<any>;
  @Input() groups: IGroups[];
  @Input() form;
  @Input() getGroupPermissions: Function;
  @Input() removeGroupPermissions: Function;

  comboboxOpened = false;
  inputSearch = "";

  ngOnInit(): void {}

  toggleCombobox() {
    this.comboboxOpened = !this.comboboxOpened;
  }

  /**
   * Retorna as opções de grupos
   */
  get getItems() {
    const textSearch = this.inputSearch.toLowerCase();
    return this.groups.filter((item) =>
      item.nome.toLowerCase().includes(textSearch)
    );
  }

  /**
   * Toggle para booleano que controla a seleção de um grupo
   */
  selectGroup(nome) {
    const index = this.groups.findIndex(group => group.nome === nome);
    if (index !== -1) {
      this.groups[index].selecionado = !this.groups[index].selecionado;
      if(this.groups[index].selecionado) {
        this.form.patchValue({
          groups: [...this.form.get('groups').value, this.groups[index]]
        })

        this.getGroupPermissions(this.groups[index]?.id);

      } else {
        const removedGroupArray = [...this.form.get('groups').value].filter(group => group.id !== this.groups[index].id);
        this.form.patchValue({
          groups: removedGroupArray
        })

        this.removeGroupPermissions(this.groups[index]?.id)
      }
    }
  }

  /**
   * Retorna os itens selecionados
   */
  get selected() {
    return this.form.get('groups').value;
  }

  /**
   * Escuta evento de click e fecha o combobox quando usuário clica fora da área dele.
   * @param event Evento de click
   */
  @HostListener("document:click", ["$event"])
  documentClick(event) {
    if (!this.combobox.nativeElement.contains(event.target)) {
      if (this.comboboxOpened) {
        this.toggleCombobox();
      }
    }
  }
}


import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CURRENT_HIRER} from '../../app/app.config';
import { IImageTheme, IThemeConfig } from 'styles/interface';
import { DefaultImagem, themeMatching } from 'styles/constant';

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  // estados que guardam o tema e as imagens da aplicação
  private theme = new BehaviorSubject<string>('default-theme');
  private images = new BehaviorSubject<IImageTheme>(DefaultImagem);

  currentTheme = this.theme.asObservable(); // Retorna o tema do contratante
  currentImages = this.images.asObservable(); // Retorna as imagens do contratante

  constructor(@Inject(CURRENT_HIRER) private currentHirer: BehaviorSubject<string>) {}

  /**
   * Define o tema no estado global da aplicação
   * @param theme tema selecionado atualmente
   */
  setTheme(hirer: string, config: IThemeConfig) {
    const {tema, imagem} = config;
    this.currentHirer.next(hirer);
    this.theme.next(themeMatching[tema]);
    this.images.next(this.buildImage(imagem));
  }

  /**
   * Atribui o tema padrão caso não consiga recuperar o tema do contratante.
   */
  setDefaultTheme() {
    this.currentHirer.next('');
    this.theme.next('default-theme');
  }

  /**
   * Constrói o objeto de imagem, verifica se o retorno do backend é nullo, se for o caso, atribui o valor default
   * @param image: objeto de imagem retornado pelo backend
   * @returns: novo objeto sem valores nulos
   */
  private buildImage(image: IImageTheme): IImageTheme {
    const imageObject: IImageTheme = {
      login: image.login !== null ? image.login : DefaultImagem.login,
      logotipo_light: image.logotipo_light !== null ? image.logotipo_light : DefaultImagem.logotipo_light
    }
    return imageObject;
  }

}

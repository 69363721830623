import { Component, Inject, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { ThemeService } from "styles/services/theme.service";
import { ThemeManagerService } from "./../styles/services/theme-manager.service";
import { ROUTER_HIRER } from "./app.config";
import { ChangePasswordService } from "./componentes/change-password/change-password.service";
import { LgpdSignatureService } from "./servico/lgpdService/lgpd-signature.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private themeService: ThemeService,
    private themeManagerService: ThemeManagerService,
    private securityService: SecurityService,
    private changePasswordService: ChangePasswordService,
    private signatureService: LgpdSignatureService,
    @Inject(ROUTER_HIRER) private routeHirer: string
  ) {}

  ngOnInit() {
    // busca o tema do contratante
    this.getThemeByTenant();
    // detector de eventos de navegação: Lida com a necessidade de atualização de senha
    // e de assinatura de termos lgpd.
    this.handleRouterEvents();
  }

  /**
   * Realizada chamada ao backend para obter as configurações de tema da instância (cliente)
   * @theme : string = Nome do tema que será aplicado no body
   */
  getThemeByTenant() {
    // hirer recebe quem é o contratante que está atualmente logado e usando o sistema.
    const userSubtenant = this.securityService.getAuthenticatedUserSubtenant();

    // se o usuário não estiver logado o userSubtenant não é encontrado,
    // então é preenchido com o hirer passado na rota (routeHirer)
    const hirer = userSubtenant ? userSubtenant : this.routeHirer;

    // recupera e aplica o tema no serviço de controle de estado
    this.themeManagerService.getThemeByHirer(hirer).subscribe({
      next: (config) => this.themeService.setTheme(hirer, config),
      error: () => this.themeService.setDefaultTheme(),
    });

    // Aplica o tema do contratante a todo o sistema do tensai
    this.themeService.currentTheme.subscribe({
      next: (theme) => (document.body.className = `${theme} neutros alertas status medals`),
      error: (erro) => console.error(erro),
    });
  }

  private blockClientFromNavigation(event: NavigationEnd, needChangePassword: boolean, needSignDocuments: boolean) {
    if (event.url !== "/" && needSignDocuments && !needChangePassword) {
      this.router.navigate(["/"]);
    }
  }

  // detecta caso o usuário ainda precise trocar a senha
  private detectNeedChangePassword(needChangePassword: boolean) {
    // se a rota acessada for diferente de '/' e o serviço retornar que ainda precisa trocar a senha
    if (needChangePassword) {
      // passo para o serviço de alterar senha para exibir a senha
      this.changePasswordService.setChangePassword(true);
    }
    // caso o usuário saia da aplicação volto para o estado inicial pra evitar request desnecessárias no dashboard
    else if (this.securityService.getAuthenticatedUser() === null) {
      this.changePasswordService.setChangePassword(true);
    }
    else {
      this.changePasswordService.setChangePassword(false);
    }

  }

  private detectNeedSignLgpdDocuments(needSignDocuments: boolean) {
    // Caso o usuário precise assinar algum dos documentos LGPD
    this.signatureService.setNeedSignDocument(needSignDocuments);
  }

  handleRouterEvents() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const needChangePassword = this.securityService.getAuthenticatedUserNeedChangePassword();
        const needSignDocuments = this.securityService.getAuthenticatedUserNeedSignDocuments();

        this.blockClientFromNavigation.call(this, event, needChangePassword, needSignDocuments);
        this.detectNeedChangePassword.call(this, needChangePassword);
        this.detectNeedSignLgpdDocuments.call(this, needSignDocuments);
      }
    })
  }
}

import {
  ICitation,
  ICitationResponse,
  IConsolidation,
  IConsolidationPayload,
  IGroup,
  IGroupResponse,
} from "../../interfaces/audit-open-answer";

/**
 * Converte as citações avulsas retornadas pelo back-end naquilo que
 * o front espera manipular
 */
export function parseAvulseQuote(data: ICitationResponse[]): ICitation[] {
  return data.map((item) => ({
    id: item.id,
    title: item.resposta !== null ? item.resposta : "Não Respondeu",
  }));
}

/**
 * Converte as consolidações retornadas pelo back-end naquilo que
 * o front espera manipular
 */
export function parseConsolidationData(data: IGroupResponse[]): IGroup[] {
  return data.map((item) => ({
    id: item.id.toString(),
    backId: item.id,
    title: item.citacao,
    typeGroup: item.tipo,
    total: item.total,
    porcentagem: item.porcentagem,
    citation: parseAvulseQuote(item.respostas),
    groups: parseConsolidationData(item.filhas)
  }));
}

/**
 * Converte as citações e consolidações para o que
 * o back espera receber
 */
export function matchPayload(data: IConsolidation): IConsolidationPayload {
  return {
    id: verifyId(data.backId),
    respostas: getAvulseAndConsolidationIds(data.respostas).respostas,
    consolidacoes: getAvulseAndConsolidationIds(data.consolidacoes).consolidacoes,
    citacao: data.title,
    tipo: data.tipo
  };
}

/**
 * Recupera os ids das citações e/ou consolidação
 * @param value Array<ICitation | IGroup>
 * @returns Array de respostas e/ou array de consolidações
 */
function getAvulseAndConsolidationIds(value: Array<ICitation | IGroup>): { respostas: number[], consolidacoes: number[] } {
  if (!value) {
    return { respostas: [], consolidacoes: [] };
  }

  const ids = value.reduce((acc, item) => {

    if ('backId' in item) { // É uma consolidação (IGroup)
      if (item.backId !== null && item.backId !== undefined) {
        acc.consolidacoes.push(item.backId);
      }

      if (item.citation &&  Array.isArray(item.citation)) {
        const nestedCitations = getAvulseAndConsolidationIds(item.citation);
        acc.respostas.push(...nestedCitations.respostas);
      }

    } else if ('id' in item && typeof item.id === 'number') { // É uma citação (ICitation)
      acc.respostas.push(item.id);
    }
    return acc;
  }, { respostas: [], consolidacoes: [] });

  return ids;
}

/**
 * Se o id for = number significa que ele foi retornado pelo back-end para representar
 * a consolidação.
 * Se o id for = string significa que é uma consolidação nova e nesse caso o back-end
 * espera null
 */
function verifyId(id: number | string) {
  return typeof id === 'number' ? id : null;
}

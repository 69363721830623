import { Injectable } from "@angular/core";
import { matchPayload } from "../../utils/parsers/consolidacaoParser";
import {
  IConsolidationPayload,
  IConsolidationResponse,
  IGroup,
} from "../../interfaces/audit-open-answer";
import { AuditoriaBetaService } from "../auditoria-beta.service";
import { errorMessages } from "../../constant/errors";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { NotificatorService } from "app/notificador/notificator.service";

@Injectable({
  providedIn: "root",
})
export class MatchPersistenceService {
  constructor(
    private auditoriaService: AuditoriaBetaService,
    private errorHandlerService: ErrorHandlerService,
    private notificatorService: NotificatorService
  ) {}

  /**
   * Pega as consolidações criadas por autoMatch e persiste no back-end
   * @param consolidations
   */
  async getAndPersistConsolidation(
    consolidations: IGroup[],
    groupsArray: IGroup[],
    surveyId: number,
    selectedQuestionId: number
  ) {
    for (let i = 0; i < consolidations.length; i++) {
      const consolidation = consolidations[i];
      const parsedData = matchPayload({
        backId: consolidation.backId,
        respostas: consolidation.citation,
        consolidacoes: [], // sempre vazio quando for automatch
        title: consolidation.title,
        tipo: consolidation.typeGroup,
      });
      const data: Partial<IConsolidationResponse> =
        await this.createOrUpdateConsolidations(
          parsedData,
          surveyId,
          selectedQuestionId
        ).catch((reason) => {
          this.errorHandlerService.handleError(
            reason,
            errorMessages.autoMatchConsolidation.title,
            errorMessages.autoMatchConsolidation.message
          );
        });
      const isCurrentGroup = groupsArray.find((c) => c.id === consolidation.id);
      isCurrentGroup.backId = data.id;
      isCurrentGroup.porcentagem = data.valor_percentual;
    }
  }

  /**
   * Pega a consolidação criada pelo usuário e persiste no back-end
   * @param consolidation
   */
  async getAndPersistUserConsolidation(
    consolidation: IGroup,
    groupsArray: IGroup[],
    surveyId: number,
    selectedQuestionId: number
  ) {
    const parsedData = matchPayload({
      backId: consolidation.backId,
      respostas: consolidation.citation,
      consolidacoes: consolidation.groups,
      title: consolidation.title,
      tipo: consolidation.typeGroup,
    });
    const data: Partial<IConsolidationResponse> =
      await this.createOrUpdateConsolidations(
        parsedData,
        surveyId,
        selectedQuestionId,
        
      ).catch((reason => {
        this.errorHandlerService.handleError(
          reason,
          errorMessages.createManualConsolidation.title,
          errorMessages.createManualConsolidation.message
        );
      }));

    const isCurrentGroup = groupsArray.find((c) => c.id === consolidation.id);
    isCurrentGroup.backId = data.id;
    isCurrentGroup.porcentagem = data.valor_percentual;

    this.notificatorService.showInfo(
      "Citações consolidadas com sucesso!",
      ""
    );
  }

  // Cria ou atualiza uma consolidação
  private createOrUpdateConsolidations(
    consolidationPayload: IConsolidationPayload,
    surveyId: number,
    selectedQuestionId: number,
  ) {
    return new Promise((resolve, reject) => {
      this.auditoriaService
        .createOrEditConsolidations(
          surveyId,
          selectedQuestionId,
          consolidationPayload
        )
        .subscribe({
          next: ({ body: data }) => {
            resolve(data as IConsolidationResponse);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { PesquisaCadastro } from "app/modulos/pesquisa-old/cadastro/model/pesquisaCadastro";
import { PesquisaCadastroService } from "../../cadastro/servico/pesquisa-cadastro.service";

export interface EstatisticasEntrevista {
  entrevistasRealizadas: number;
  entrevistasAuditadas: number;
  entrevistasRejeitadas: number;
  entrevistasAprovadas: number;
  conformidade: number;
  desconformidade: number;
  abaixoTempoMinimo: number;
  acimaTempoMinimo: number;
}

export interface Localizacao {
  latitude: number;
  longitude: number;
  raio: number;
  idLocalidade: number;
}

export interface Entrevista {
  idEntrevista: number;
  desconformidades: any[];
  localizacao: Localizacao;
}

export interface ResultadosDiario {
  data: string;
  coletas: number;
  entrevistas: Entrevista[];
}

export interface ProducaoDiaria {
  previsao: number;
  resultadosDiarios: ResultadosDiario[];
}

export interface LocalizacoesPesquisa {
  nome: string;
  latitude: number;
  longitude: number;
  raio: number;
}

export interface DadosPesquisa {
  totalAmostras: number;
  localizacoesPesquisa: LocalizacoesPesquisa[];
}

export interface RootObject {
  estatisticasEntrevista: EstatisticasEntrevista;
  producaoDiaria: ProducaoDiaria;
  dadosPesquisa: DadosPesquisa;
}

@Component({
  selector: "app-header-informacoes",
  templateUrl: "./header-informacoes.component.html",
  styleUrls: ["./header-informacoes.component.scss"],
})
export class HeaderInformacoesComponent implements OnInit {
  @Input() pesquisaBody: PesquisaCadastro;

  totalDeAmostras: number = 0;
  totalDeColetas: number = 0;
  totalDeEntrevistasRejeitas: number = 0;

  constructor(private pesquisaCadastroService: PesquisaCadastroService) {}

  ngOnInit() {
    this.pesquisaCadastroService
      .getEstatisticasByIdOfSurvey(this.pesquisaBody.id)
      .subscribe((response: RootObject) => {
        this.totalDeAmostras =
          this.pesquisaBody.configuracaoPesquisa.amostrasOnline +
          this.pesquisaBody.configuracaoPesquisa.amostrasPresenciais;

        this.totalDeEntrevistasRejeitas =
          (response.estatisticasEntrevista.entrevistasRejeitadas * 100) /
          this.totalDeAmostras;

        this.totalDeEntrevistasRejeitas.toFixed(2);

        this.totalDeColetas = response.producaoDiaria.resultadosDiarios.reduce(
          (acc, currentValue) => {
            acc += currentValue.coletas;

            return acc;
          },
          0
        );
      });
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";

export interface ICardData {
  id: string | number;
  nome: string;
  valor: number | string;
  foto?: {
    arquivo: string;
  };
}

@Component({
  selector: "app-visao-geral",
  templateUrl: "./visao-geral.component.html",
  styleUrls: ["./visao-geral.component.scss"],
})
export class VisaoGeralComponent implements OnInit {
  surveyId: number | string

  constructor(private route: ActivatedRoute) {
    this.surveyId = this.route.snapshot.paramMap.get("surveyId");
  }

  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Produtividade",
      itemLink: "/produtividade-beta",
      active: false,
    },
    {
      itemName: "Avaliação da Pandemia pelo Governo Federal do estado do Piauí",
      itemLink: "/produtividade-beta/visao-geral/1",
      active: true,
    },
  ];

  header = {
    titulo: "Avaliação da Pandemia pelo Governo Federal do estado do Piauí",
    periodo: {
      titulo: "Período de pesquisa",
      inicio: "04/02/23",
      fim: "06/02/23",
    },
    resumo: {
      titulo: "Resumo da auditoria",
      aprovadas: 56,
      reprovadas: 24,
    },
    progresso: {
      total: 400,
      realizadas: 200,
      porcentagem: 50,
    }
  }

  coletasPorDia = {
    media: 42,
    operadores: [
      {
        id: 4,
        nome: "Victor Abiscula",
        valor: 58.7,
        foto: {
          arquivo: "https://avatars.githubusercontent.com/u/85578784?v=4",
        },
      },
      {
        id: 5,
        nome: "Lucas Duete",
        valor: 58.1,
        foto: {
          arquivo: "https://avatars.githubusercontent.com/u/18538755?v=4",
        },
      },
      {
        id: 10,
        nome: "Michelle Oliveira",
        valor: 55.5,
        foto: {
          arquivo:
          "https://avatars.githubusercontent.com/u/10780987?v=4",
        },
      },
    ],
  };

  aprovacoes = {
    media: 96,
    operadores: [
      {
        id: 2,
        nome: "Bruno Viana",
        valor: 100,
      },
      {
        id: 7,
        nome: "Augusto Roberto",
        valor: 98,
      },
    ],
  };

  tempoMedioColeta = {
    media: "00:10:20",
    operadores: [
      {
        id: 9,
        nome: "Camila Pitanga",
        valor: "00:11:21",
      },
      {
        id: 10,
        nome: "Ramones Santana",
        valor: "00:10:22",
      },
    ] as ICardData[],
  };

  ngOnInit(): void {}

  formatterDataColetas(): ICardData[] {
    const formatterData: ICardData[] = this.coletasPorDia.operadores;

    // inserindo dados para completar a visualização
    while (formatterData.length < 5) {
      formatterData.push({
        id: "-",
        nome: "-",
        valor: "0 Coletas",
      });
    }

    return formatterData;
  }

  formatterDataAprovacoes(): ICardData[] {
    const formatterData: ICardData[] = this.aprovacoes.operadores;

    // inserindo dados para completar a visualização
    while (formatterData.length < 5) {
      formatterData.push({
        id: "-",
        nome: "-",
        valor: "0",
      });
    }

    return formatterData;
  }

  formatterDataTempoMedio(): ICardData[] {
    const formatterData: ICardData[] = this.tempoMedioColeta.operadores;

    // inserindo dados para completar a visualização
    while (formatterData.length < 6) {
      formatterData.push({
        id: "-",
        nome: "-",
        valor: "00:00:00",
      });
    }

    return formatterData;
  }
}

import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { NewFotoUploaderComponent } from "app/util/componente/new-foto-uploader/new-foto-uploader.component";
import { EMAIL_REGEX } from "../constants";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import validadorCpf from "app/util/validador/validadorCpf";
import { ModalService } from "app/componentes/modal/modal.service";
import { Location } from "@angular/common";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import { IOperatorEditPayload, IOperatorPostPayload } from "../interfaces";
import { createOperatorPayload, updateOperatorPayload } from "../utils";
import { OperadorService } from "../services/operador-beta.service";
import { NotificatorService } from "app/notificador/notificator.service";
import { OperatorNavigateService } from "../services/operator-navigate.service";
import { ActivatedRoute } from "@angular/router";
import { IVerifyLoginCredentials } from "app/infraestrutura/security/service/loginCredentials";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { Authority } from "app/infraestrutura/security/authority";
import dates from "app/util/misc/dates";

enum ActiveModal {
  PASSWORD = 'PASSWORD',
  EMAIL = 'EMAIL',
}

@Component({
  selector: "app-cadastro-operadores-beta",
  templateUrl: "./cadastro.component.html",
  styleUrls: ["./cadastro.component.scss"],
})
export class CadastroOperadorBetaComponent implements OnInit {
  @ViewChild("statusDropdown", { static: true }) statusDropdown;
  @ViewChild(NewFotoUploaderComponent, { static: true })
  newFotoUploaderComponent: NewFotoUploaderComponent;

  emailRegex = EMAIL_REGEX;
  photoSize = {
    width: '100px',
    height: '100px',
    iconHeight: '30px',
    iconWidth: '30px',
    iconFontSize: '12px'
  }
  dataBreadcrumb: IItemBreadcrumb[];
  activeModal: ActiveModal;

  isEdicao: boolean = false;
  form: FormGroup;
  previousValue;
  isLoading: boolean = false;
  statusIsOpen = false;
  hasChange: boolean = false;
  cancelCheckboxValue: boolean = false;
  editCheckboxValue: boolean = false;
  submitted: boolean = false;
  completeRequest: boolean = false;
  matricula: string;
  operatorId: number;
  newEmail: string;
  personAndUserIds = {
    userId: null,
    personId: null
  }
  credentials: IVerifyLoginCredentials = {
    login: this.securityService.getAuthenticatedUser().login,
    password: ''
  }

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private location: Location,
    private operatorService: OperadorService,
    private notificator: NotificatorService,
    private securityService: SecurityService,
    private operatorNavigateService: OperatorNavigateService,
  ) {}

  ngOnInit(): void {
    this.form = this.buildForm();

    this.isEdicao = this.activatedRoute.snapshot.queryParams.hasOwnProperty('id') ? true : false;

    if(this.isEdicao) {
      this.operatorId = this.activatedRoute.snapshot.queryParams?.id
      this.buildFormEditMode();
    }

    this.dataBreadcrumb = [
      {
        itemName: "início",
        itemLink: "/",
        active: false,
      },
      {
        itemName: "Operadores",
        itemLink: "/operador-beta",
        active: false,
      },
      {
        itemName: `${this.isEdicao ? "Editar operador" : "Adicionar operador"}`,
        itemLink: `/operador-beta/${this.isEdicao ? "atualizar" : "cadastro"}`,
        active: true,
      },
    ];

    this.modalInputControl();
    this.onInputEmailChange();
    this.controlDateInputErros();
    this.onValueChange();
  }

  /**
   * Controla o valor do input da modal e atribui as devidas validações
   */
  modalInputControl() {
    this.modalService.getInput().subscribe({
      next: (inputValue) => {
        if (this.activeModal === ActiveModal.PASSWORD) {
          this.credentials.password = inputValue

          if(inputValue !== null && !inputValue.length) {
            this.modalService.handleInputErrors(['Senha obrigatória']);
          } else {
            this.modalService.cleanInputErrors();
          }
        } else if (this.activeModal === ActiveModal.EMAIL) {
          this.newEmail = inputValue;

          if(inputValue !== null && !inputValue.length) {
            this.modalService.handleInputErrors(['Campo obrigatório']);
          } else {
            this.modalService.cleanInputErrors();
          }
        }
      }
    })
  }

  /**
   * Realiza a construção do formulário com os dados e validações inciais
   */
  buildForm(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      photo: [""],
      name: ["", [Validators.required, Validators.maxLength(70)]],
      email: ["", [Validators.required, Validators.pattern(this.emailRegex)]],
      cpf: ["", [Validators.required, validadorCpf]],
      vinculoInicial: [null, Validators.required],
      vinculoFinal: [null, Validators.required],
      status: [true, [Validators.required]],
    });
  }

  /**
   * Realiza a construção do formulário com os dados retornados do backend
   */
  buildFormEditMode() {
    this.operatorService.getOperatorById(this.operatorId).subscribe({
      next: (data: IOperatorEditPayload) => {
        this.form.setValue({
          id: data?.id,
          photo: data?.foto,
          name: data?.pessoa.nome,
          email: data?.contato.email,
          cpf: data?.pessoa.cpf,
          vinculoInicial: data?.periodoInicial,
          vinculoFinal: data?.periodoFinal,
          status: data?.usuario.ativo,
        })
        this.matricula = data?.matricula
        this.personAndUserIds = {
          userId: data?.usuario.id,
          personId: data?.pessoa.id
        }
        data?.foto !== null && this.newFotoUploaderComponent.loadImageByUrl(data?.foto);
        this.previousValue = this.form.value;
      },
      complete: () => {
        this.onValueChange()
        this.hasChange = false;
        this.completeRequest = true;
      }
    })
  }

  /**
   * Intercepta as mudanças no input de email para transformar todo valor
   * inserido em minusculo
   */
  onInputEmailChange() {
    this.form.get('email').valueChanges.subscribe(value => {
      this.form.get('email').patchValue(value.toLowerCase(), { emitEvent: false });
    });
  }

  /**
   * Intercepta se houve alteração no input de vinculoInicial e/ou no vinculoFinal e
   * chamada o metodo responsável por verificar se o intervalo é valido
   */
  controlDateInputErros() {
    this.form.get('vinculoInicial').valueChanges.subscribe(() => {
      this.checkDateisValid();
    });

    this.form.get('vinculoFinal').valueChanges.subscribe(() => {
      this.checkDateisValid();
    })
  }

  /**
   * Intercepta as mudanças no formulario para saber se o usuário alterou algo durante o cadastro/edição
   */
  onValueChange(){
    const initialValue = this.form.value;
    this.form.valueChanges.subscribe(() => {
      this.hasChange = Object.keys(initialValue).some(key => this.form.value[key] != initialValue[key])
    });
  }

  /**
   * Adiciona imagem settada ao formGroup (ou nulo se não houver upload)
   */
  updateImage():void {
    if (this.newFotoUploaderComponent.defaultImage === this.newFotoUploaderComponent.url) {
      this.form.get('photo')?.setValue(null);
    } else {
      this.form.get('photo')?.setValue(this.newFotoUploaderComponent.url);
    }
  }

  // Controla o valor do campo status
  handleStatusClick(type: boolean): void {
    this.form.get("status")?.setValue(type);
  }

  // realiza o toggle do select de status
  toggleStatus(): void {
    this.statusIsOpen = !this.statusIsOpen;
  }

  /**
   * Escuta evento de click e fecha select quando usuário clica fora da área dele.
   * @param event Evento de click
   */
  @HostListener("document:click", ["$event"])
  documentClick(event: Event) {
    if (!this.statusDropdown.nativeElement.contains(event.target)) {
      if (this.statusIsOpen) {
        this.toggleStatus();
      }
    }
  }

  /**
   * Controla a seleção da data de vinculo inicial
   * @param date: data selecionada
   */
  selectInitialDate(date) {
    if(date.answer !== 'Data inválida') {
      this.form.get('vinculoInicial')?.setValue(date.answer);
    } else {
      this.form.get('vinculoInicial')?.setValue(null);
    }
  }

  /**
   * Controla a seleção da data de vinculo final
   * @param date: data selecionada
   */
  selectFinalDate(date) {
    if(date.answer !== 'Data inválida') {
      this.form.get('vinculoFinal')?.setValue(date.answer);
    } else {
      this.form.get('vinculoFinal')?.setValue(null);
    }
  }

  /**
   * Encerra o cadastro
   */
  handleClose(): void {
    this.modalService.getCheckbox().subscribe({next: (data) => this.cancelCheckboxValue = data})
    if(this.hasChange && !this.cancelCheckboxValue) {
      this.modalService.showModal({
        icon: 'fa-regular fa-door-open',
        title: 'Sair sem salvar',
        messageModal: 'Deseja mesmo descartar as alteraçoes realizadas no perfil deste funcionário?',
        btnTitlePositive: 'Sair sem salvar',
        btnTitleNegative: 'Voltar',
        checkbox: true,
        positiveCallback: () => this.location.back()
      })
    } else {
      this.location.back()
    }
  }

  /**
   * Toca todos os campos do formulario para indicar os erros para o usuário
   * @param form: Atributo que representa o formulario
   */
  markFieldsAsTouched(form: FormGroup) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);

      if (control instanceof FormGroup) {
        this.markFieldsAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  /**
   * Retorna o texto da modal baseado na situação do operador (ativo/inativo)
   * @returns mensagem da modal
   */
  getRegisterModalText(): string {
    if(this.form.get('status')?.value) {
      return `Deseja também ativar o cadastro de ${this.form.get('name')?.value}?<br> Um e-mail de definição de senha será enviado para finalização da ativação.`
    } else {
      return 'Você está adicionando um operador com a situação <b>Inativo</b>. Deseja mesmo continuar?'
    }
  }

  /**
   * Request para cadastro de um operador
   */
  registerNewOperator() {
    const payload: IOperatorPostPayload = createOperatorPayload(this.form.value);
    this.operatorService.createOperator(payload).subscribe({
      next: (data) => {
        this.operatorNavigateService.setOperatorId(data.id)
      },
      complete: () => {
        this.notificator.showInfo(
          'Operador adicionado!',
          this.form.get('status')?.value ? 'E-mail de definição de senha enviado!' : ''
        );
        this.location.back();
      },
      error: () => {
        this.notificator.showError(
          'Erro no cadastro!',
          'Ocorreu um erro ao cadastrar o operador'
        );
      }
    })
  }

  /**
   * Controla todos os casos de salvamento numa edição de funcionário
   */
  editCases() {
    if(this.previousValue.status !== this.form.get('status').value) {
      if(this.form.get('status').value === true) {
        this.editWithActivation(); //ativando operador
      } else {
        this.editWithInactivation(); //inativando operador
      }
    } else {
      this.onlyEdit();
    }
  }

  /**
   * CASE: Edita o operador e o ativa
   */
  editWithActivation() {
    this.modalService.getCheckbox().subscribe({next: (data) => this.editCheckboxValue = data})
    if(this.hasChange && !this.editCheckboxValue) {
      this.modalService.showModal({
        icon: 'fa-regular fa-toggle-large-off',
        title: 'Ativar operador',
        messageModal: `Deseja mesmo alterar a situação de ${this.form.get('name')?.value} para ativo?`,
        btnTitlePositive: 'Ativar',
        btnTitleNegative: 'Cancelar',
        checkbox: true,
        positiveCallback: () => this.operatorUpdate('Operador ativado!')
      });
    } else {
      this.operatorUpdate()
    }
  }

  /**
   * CASE: Edita o operador e o inativa
   */
  editWithInactivation() {
    this.modalService.getCheckbox().subscribe({next: (data) => this.editCheckboxValue = data})
    if(this.hasChange && !this.editCheckboxValue) {
      this.modalService.showModal({
        icon: 'fa-regular fa-circle-xmark',
        title: 'Inativar operador',
        messageModal: `Deseja mesmo inativar o operador <b>${this.form.get('name')?.value}</b>?`,
        btnTitlePositive: 'Inativar',
        btnTitleNegative: 'Cancelar',
        checkbox: true,
        positiveCallback: () => this.operatorUpdate()
      });
    } else {
      this.operatorUpdate()
    }
  }

  /**
   * CASE: Apenas edição sem alteração no status do operador
   */
  onlyEdit() {
    this.modalService.getCheckbox().subscribe({next: (data) => this.editCheckboxValue = data})
    if(this.hasChange && !this.editCheckboxValue) {
      this.modalService.showModal({
        icon: 'fa-regular fa-pen-to-square',
        title: 'Salvar edição',
        messageModal: 'Deseja salvar as alterações realizadas no perfil deste operador?',
        btnTitlePositive: 'Salvar',
        btnTitleNegative: 'Cancelar',
        checkbox: true,
        positiveCallback: () => this.operatorUpdate()
      });
    } else {
      this.operatorUpdate()
    }
  }

  /**
   * Realiza a edição dos dados do operador
   */
  operatorUpdate(notifyTitle: string = 'Alterações salvas com sucesso!', notifyMessage: string = null) {
    const payload: IOperatorEditPayload = updateOperatorPayload(this.form.value, this.personAndUserIds);

    this.operatorService.updateOperator(payload).subscribe({
      complete: () => {
        this.notificator.showInfo(
          notifyTitle,
          notifyMessage
        );
        this.operatorNavigateService.setOperatorId(payload.id)
        this.location.back();
      },
      error: () => {
        this.notificator.showError(
          'Erro ao salvar!',
          null
        );
      }
    })
  }

  /**
   * Verifica se o range de data escolhido é valido
   */
  checkDateisValid() {
    let initialDate = dates.dateStrToUnix(this.form.get('vinculoInicial')?.value)
    let finalDate = dates.dateStrToUnix(this.form.get('vinculoFinal')?.value)

    if (initialDate > finalDate) {
      this.form.get('vinculoFinal')?.value === null
        ? this.form.get('vinculoFinal').setErrors({ required: true })
        : this.form.get('vinculoFinal').setErrors({ dateError: true });
    } else {
      if(this.form.get('vinculoFinal')?.value !== null) {
        this.form.get('vinculoFinal').setErrors(null);
      }
    }
  }

  /**
   * Envia os dados de cadastro do operador ao back-end
   */
  handleSubmit():void  {
    this.updateImage();
    this.checkDateisValid();
    this.submitted = true;
    this.markFieldsAsTouched(this.form);
    if(this.form.status === 'VALID') {
      if(this.isEdicao) {
        this.editCases();
      } else {
        this.modalService.showModal({
          icon: 'fa-regular fa-clipboard-user',
          title: 'Adicionar operador',
          messageModal: this.getRegisterModalText(),
          btnTitlePositive: this.form.get('status')?.value ? 'Ativar' :  'Continuar',
          btnTitleNegative: 'Cancelar',
          positiveCallback: () => this.registerNewOperator()
        });
      }
    }
  }

  /**
   * Chama a modal para troca do e-mail de um operador cadastrado.
   */
  handleChangeEmail() {
    this.activeModal = ActiveModal.EMAIL;
    this.modalService.showModal({
      icon: 'fa-regular fa-envelope',
      title: 'Alteração de e-mail',
      messageModal:
      `Uma mensagem de confirmação será enviada para o novo e-mail do operador. <br><br>
      Novo e-mail*:`,
      btnTitlePositive: 'Alterar',
      btnTitleNegative: 'Cancelar',
      close: false,
      inputConfig: {
        show: true,
        placeholder: 'mario@email.com',
        eyeIcon: false,
      },
      positiveCallback: () =>
      this.newEmail?.length
        ? this.changeEmail()
        : this.modalService.handleInputErrors(['Campo obrigatório']),
    });
  }

  /**
   * Realiza a requisição para a mudança de e-mail do funcionário
   */
  changeEmail() {
    this.previousValue.email = this.newEmail;
    const payload = updateOperatorPayload(this.previousValue, this.personAndUserIds);
    const regex = new RegExp(this.emailRegex)

    if (this.newEmail?.length && !regex.test(this.newEmail)) {
      this.modalService.handleInputErrors(['Formato de e-mail inválido']);
    } else {
      this.modalService.cleanInputErrors();

      this.modalService.setLoading(true);
      this.operatorService.updateOperator(payload).subscribe({
        complete: () => {
          this.notificator.showInfo(
            'Alterações salvas!',
            'Confirmação de mudança de e-mail enviada!'
          );
          this.modalService.setLoading(false);
          this.updateEmailExchange();
          this.modalService.handleCloseModal();
        },
        error: ({error}) => {
          this.modalService.setLoading(false);
          if(error.status === 422) {
            this.modalService.handleInputErrors(['E-mail já cadastrado']);
          } else {
            this.notificator.showError(
              'Erro ao salvar!',
              null
            );
          }
        }
      })
    }
  }

  /**
   * Atualiza o formulário com o novo email alterado
   */
  updateEmailExchange() {
    this.form.patchValue({
      email: this.newEmail
    })
  }
}

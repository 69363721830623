import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import { FilterIcon, FilterTypes } from "app/componentes/filter-select/constants";
import { FilterConfigs, SearchInputConfig } from "app/util/componente/genericTable/interfaces/filters";
import { TableHead } from "app/util/componente/genericTable/interfaces/tHead";
import { TableRow } from "app/util/componente/genericTable/interfaces/tRow";
import { TableGlobalConfig } from "app/util/componente/genericTable/interfaces/table";
import {
  iconCellFactory,
  imageCellFactory,
  textCellFactory,
} from "app/util/componente/genericTable/utils/cellFactory";
import { tHeadFactory } from "app/util/componente/genericTable/utils/tHeadFactory";

@Component({
  selector: "app-listagem-operadores",
  templateUrl: "./listagem-operadores.component.html",
  styleUrls: ["./listagem-operadores.component.scss"],
})
export class LIstagemOperadoresComponent implements OnInit {

  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Produtividade",
      itemLink: "/produtividade-beta",
      active: false,
    },
    {
      itemName: "Avaliação da Pandemia pelo Governo Federal do estado do Piauí",
      itemLink: "/produtividade-beta/visao-geral/1/operadores",
      active: true,
    },
  ];

  header = {
    titulo: "Avaliação da Pandemia pelo Governo Federal do estado do Piauí",
    periodo: {
      titulo: "Duração da pesquisa",
      inicio: "04/02/23",
      fim: "06/02/23",
    },
    resumo: {
      titulo: "Auditoria da pesquisa",
      aprovadas: 56,
      reprovadas: 24,
    },
    progresso: {
      total: 400,
      realizadas: 200,
      porcentagem: 50,
    },
  };

  tHeadOperator: TableHead[] = [
    tHeadFactory("Operador").build(),
    tHeadFactory("Coletas cumpridas").build(),
    tHeadFactory("Dias trabalhados").build(),
    tHeadFactory("Coletas aprovadas").build(),
    tHeadFactory("Coletas reprovadas").build(),
    tHeadFactory("Avaliação geral")
      .class((cell) => this.assessmentStyle(cell.textValue))
      .cellDataToolTip({
        label: 'Nota da avaliação',
        position: "right"
      })
      .build(),
    tHeadFactory("Visualizar").alignType('center').cellAlign('center').iconDefaultValue({
      icon: [
        "fa-regular fa-eye",
      ],
      behavior: 'button'
    }).cellStyles({fontSize: '16px'}).build(),
  ];

  tBodyOperator: TableRow[] = [
    {
      cells: [
        imageCellFactory(
          "https://alfa-tensai-teste.s3.amazonaws.com/tensai/funcionario/jtLTKdQYmp_1.jpeg",
          "Rodrigo Faro"
        ).build(),
        textCellFactory("20 de 30").build(),
        textCellFactory("1").build(),
        textCellFactory("8").build(),
        textCellFactory("1").build(),
        textCellFactory("9.2").build(),
        iconCellFactory().build(),
      ],
    },
    {
      cells: [
        imageCellFactory(
          "https://avatars.githubusercontent.com/u/44440717?v=4",
          "Joao da morena"
        ).build(),
        textCellFactory("10 de 10").build(),
        textCellFactory("6").build(),
        textCellFactory("10").build(),
        textCellFactory("0").build(),
        textCellFactory("4.8").build(),
        iconCellFactory().build(),
      ],
    },
    {
      cells: [
        imageCellFactory(
          "https://alfa-tensai-teste.s3.amazonaws.com/tensai/funcionario/gOJT4jSB16_2.jpeg",
          "Ketlyn Wernnevon"
        ).build(),
        textCellFactory("3 de 40").build(),
        textCellFactory("4").build(),
        textCellFactory("10").build(),
        textCellFactory("3").build(),
        textCellFactory("5.8").build(),
        iconCellFactory().build(),
      ],
    },
    {
      cells: [
        imageCellFactory(
          "https://avatars.githubusercontent.com/u/85578784?v=4",
          "Gutinho"
        ).build(),
        textCellFactory("50 de 50").build(),
        textCellFactory("99").build(),
        textCellFactory("50").build(),
        textCellFactory("0").build(),
        textCellFactory("10").build(),
        iconCellFactory().build(),
      ],
    },
    {
      cells: [
        imageCellFactory(
          "https://xsgames.co/randomusers/avatar.php?g=male",
          "Filho do meu filho"
        ).build(),
        textCellFactory("3 de 16").build(),
        textCellFactory("4").build(),
        textCellFactory("7").build(),
        textCellFactory("0").build(),
        textCellFactory("6").build(),
        iconCellFactory().build(),
      ],
    },
    {
      cells: [
        imageCellFactory(
          "https://xsgames.co/randomusers/avatar.php?g=female",
          "Aluno mais fraco do caba lá"
        ).build(),
        textCellFactory("50 de 99").build(),
        textCellFactory("56").build(),
        textCellFactory("0").build(),
        textCellFactory("50").build(),
        textCellFactory("3.3").build(),
        iconCellFactory().build(),
      ],
    },
    {
      cells: [
        imageCellFactory(
          "https://xsgames.co/randomusers/avatar.php?g=male",
          "Duete do front"
        ).build(),
        textCellFactory("16 de 16").build(),
        textCellFactory("4").build(),
        textCellFactory("7").build(),
        textCellFactory("0").build(),
        textCellFactory("8").build(),
        iconCellFactory().build(),
      ],
    },
    {
      cells: [
        imageCellFactory(
          "https://xsgames.co/randomusers/avatar.php?g=female",
          "Texilidi chibi"
        ).build(),
        textCellFactory("10 de 16").build(),
        textCellFactory("4").build(),
        textCellFactory("7").build(),
        textCellFactory("4").build(),
        textCellFactory("3").build(),
        iconCellFactory().build(),
      ],
    },
    {
      cells: [
        imageCellFactory(
          "https://xsgames.co/randomusers/avatar.php?g=male",
          "Araubiano"
        ).build(),
        textCellFactory("5 de 16").build(),
        textCellFactory("11").build(),
        textCellFactory("5").build(),
        textCellFactory("0").build(),
        textCellFactory("1").build(),
        iconCellFactory().build(),
      ],
    },
    {
      cells: [
        imageCellFactory(
          "https://xsgames.co/randomusers/avatar.php?g=male",
          "Sem ideia pra nome"
        ).build(),
        textCellFactory("5 de 16").build(),
        textCellFactory("11").build(),
        textCellFactory("5").build(),
        textCellFactory("0").build(),
        textCellFactory("7.8").build(),
        iconCellFactory().build(),
      ],
    },
    {
      cells: [
        imageCellFactory(
          "https://xsgames.co/randomusers/avatar.php?g=male",
          "Gordão foguetes"
        ).build(),
        textCellFactory("10 de 10").build(),
        textCellFactory("11").build(),
        textCellFactory("10").build(),
        textCellFactory("0").build(),
        textCellFactory("10").build(),
        iconCellFactory().build(),
      ],
    },
  ];

  tableConfig: TableGlobalConfig = {
    colWidths: ["0.33", "0.11", "0.11", "0.11", "0.11", "0.11", "0.11"],
    headerHeight: "72px",
    rowHeight: "72px",
    rowStyles: {
      fontSize: '14px',
      border: '1px solid var(--tertiary)'
    },
    rowBackgroundColors: {
      even: 'var(--white)',
      odd: 'var(--white)'
    }
  };

  tableFilter: FilterConfigs[] = [
    {
      type: FilterTypes.RADIO,
      icon: FilterIcon.SORTING,
      placeholder: 'Ordenar',
      options: [
        {
          id: 1,
          label:'Operador A a Z'
        },
        {
          id: 2,
          label: 'Operador Z a A'
        },
        {
          id: 3,
          label: 'Coletas cumpridas por ordem crescente'
        },
        {
          id: 4,
          label: 'Coletas cumpridas por ordem decrescente'
        },
        {
          id: 5,
          label: 'Dias trabalhados por ordem crescente'
        },
        {
          id: 6,
          label: 'Dias trabalhados por ordem decrescente'
        },
        {
          id: 7,
          label: 'Coletas aprovadas por ordem crescente'
        },
        {
          id: 8,
          label: 'Coletas aprovadas por ordem decrescente'
        },
        {
          id: 9,
          label: 'Avaliação geral por ordem crescente'
        },
        {
          id: 10,
          label: 'Avaliação geral por ordem decrescente'
        },
      ]
    }
  ]

  searchConfig: SearchInputConfig = {
    placeholder: 'Busque por operador',
    delay: 2000
  }

  constructor(private router: Router) {}

  ngOnInit(): void {}

  assessmentStyle(textValue): string {
    if (+textValue > 7.0) {
      return 'green-background'
    } else if (+textValue > 5.0) {
      return 'yellow-background'
    } else {
      return 'red-background'
    }
  }

  handleEventTable($event) {
    const { eventType } = $event;
    const eventTable = {
      ["CELL_ICON_CLICK"]: this.handleClickView.bind(this),
    };
    if (Object.keys(eventTable).includes(eventType)) {
      eventTable[eventType]($event);
    }
  }

  handleClickView($event) {
    this.router.navigate(["/produtividade-beta/visao-geral/1/operadores/operador", $event.rowId]);
  }

}

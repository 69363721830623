import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { IPodioData } from 'app/modulos/produtividade-beta/interfaces';

@Component({
  selector: 'app-podium',
  templateUrl: './podium.component.html',
  styleUrls: ['./podium.component.scss']
})
export class PodiumComponent implements OnInit {

  showTooltip: boolean = false;
  podiumData: IPodioData;
  hasCollections: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  surveyId = this.route.snapshot.params["id"];

  ngOnInit(): void {
    // remover mock no momento da integração com back-end
    this.podiumData = {
      "podio": [
          {
            "id": 1,
            "nome": "Cláudia Maria",
            "avaliacao_geral": 10,
            "foto": {
                "arquivo": "https://alfa-tensai-teste.s3.amazonaws.com/tensai/funcionario/5J9KLTDMBo_1.jpeg"
            }
          },
          {
            "id": 2,
            "nome": "Bruno Viana",
            "avaliacao_geral": 6.8,
            "foto": {
                "arquivo": "https://alfa-tensai-teste.s3.amazonaws.com/tensai/funcionario/l6Wt0XP1m8_1.jpeg"
            }
          },
      ]
    }
  }

  navigate() {
    this.router.navigate([`/produtividade-beta/visao-geral/${this.surveyId}/operadores`]);
  }

}
